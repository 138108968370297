<div *ngIf="envs && envs.length > 1; else NoEnvs" class="environment-container">
  <ista-daytona-select
    (ngModelChange)="onSelectChange($event)"
    [(ngModel)]="selectedEnv"
    [items]="envs | environmentDropdown"
    [selected]="selectedEnv?.id"
  ></ista-daytona-select>
</div>

<ng-template #NoEnvs>
  <p *ngIf="!envs">
    {{ 'environment.envFailure' | translate }}
  </p>
</ng-template>
