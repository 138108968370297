import { FilterTab } from 'shared/ui/filter/filter-tab.interface';

export const UNASSIGNED_SITES_GROUP_ID = 0;

export const nullValueDisplay = '-';
export const naValueDisplay = 'n/a';

export const PortfolioChartDefaultMainFilter: FilterTab = {
  left: [
    {
      label: '',
      value: 'consumption',
    },
  ],
  selectedYear: {
    from: '',
    until: '',
  },
};

export const PortfolioChartDefaultSubFilter: FilterTab = {
  left: [
    {
      label: 'types.all',
      value: 'heatingAndHotWater',
    },
  ],
  selectedYear: {
    from: '',
    until: '',
  },
};

export const ENVIRONMENT_ID_QUERY_PARAM = 'environmentId';
export const YEAR_QUERY_PARAM = 'year';
