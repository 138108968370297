export enum DeepLinkingIds {
  PORTFOLIO_CONSUMPTION_CHART = 'consumptionChart',
  PORTFOLIO_OVERVIEW = 'portfolioOverview',
  HELP = 'help',
  REPORTING = 'reporting',
}

export type DeepLinkingCustomParams = Record<DeepLinkingIds, string[]>;

export const DeepLinkingCustomParamsKeys: DeepLinkingCustomParams = {
  [DeepLinkingIds.PORTFOLIO_CONSUMPTION_CHART]: ['subTab', 'mainTab'],
  [DeepLinkingIds.PORTFOLIO_OVERVIEW]: [],
  [DeepLinkingIds.HELP]: ['id', 'tab'],
  [DeepLinkingIds.REPORTING]: ['tab'],
};
