@if (dataSource) {
  <div class="overflow-x-auto max-h-[36rem]">
    <table
      mat-table
      [dataSource]="dataSource"
      class="w-full"
      aria-label="table"
      uiTable
    >
      <!-- Define the columns -->
      <ng-container *ngFor="let column of headerLabels" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef>
          <div
            class="flex flex-auto gap-2 items-center text-base-600 text-sm font-extrabold"
            *ngIf="getHeaderItem(column) as headerItem"
          >
            @if (headerItem.icon; as icon) {
              @if (icon.isSvgIcon) {
                <mat-icon [svgIcon]="icon.name" class="icon-size-6" [class]="icon.colorClass"></mat-icon>
              } @else {
                <mat-icon class="icon-size-6" [class]="icon.colorClass">{{ icon.name }}</mat-icon>
              }
            }
            <span [innerHTML]="headerItem['label'] | translate | safe"></span>
          </div>
        </th>

        <td
          mat-cell
          *matCellDef="let item"
          customToolTip
          [showToolTip]="item[column]?.toolTip"
          [contentTemplate]="toolTip"
        >
          <ng-container>
            @if ($any(item[column])?.value?.value) {
              <ista-daytona-value-unit
                [value]="getCreatedValueItem(item[column].value)"
                [classContainer]="'text-sm text-primary-1000 font-normal'"
                [classUnit]="'text-sm text-primary-1000 font-normal'"
              ></ista-daytona-value-unit>
            } @else {
              <span
                class="text-sm text-primary-1000 font-normal"
                [innerHTML]="item[column]?.value ? getFormattedValue(item[column]?.value) : 'N/A'"
              ></span>
            }
          </ng-container>

          <ng-template #toolTip>
            <div class="custom-tooltip" *ngIf="item[column]?.toolTip">
            <span
              [innerText]="item[column].toolTip.value"
              *ngIf="item[column].toolTip.value"
            ></span>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headerLabels; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: headerLabels"></tr>
    </table>
  </div>
} @else {
  <ista-daytona-loader [diameter]="'64'"></ista-daytona-loader>
}
<mat-paginator
  [pageSizeOptions]="[10, 20, 50]"
  aria-label="Select page of periodic elements"
  class="mt-6 mat-mdc-paginator"
  showFirstLastButtons
>
</mat-paginator>


