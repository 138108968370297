<div class="chart--container">
  <canvas
    #chart
    [data]="doughnutChartData"
    [options]="doughnutOptions"
    [type]="doughnutType"
    baseChart
  ></canvas>
  <div
    #infoText
    *ngIf="config"
    [style.left.px]="infoTextLeft"
    class="chart--innerText top-15"
  >
    @if (icon) {
      @if (icon.isSvgIcon) {
        <mat-icon class="icon-size-16" [class]="icon.colorClass" [svgIcon]="icon.name"></mat-icon>
      } @else {
        <mat-icon class="icon-size-16" [color]="icon.colorClass">{{ icon.name }}</mat-icon>
      }
    }

    @if (value) {
      @if (value.value | formatNumber: value.unit; as formattedValue) {
        @if (formattedValue.value) {
          <span
            class="text-5xl font-extrabold italic"
            [innerText]="formattedValue.value | number : getFormatNumberDigits() : 'de'"
          >
        </span>
        }
        @if (formattedValue.unitWithPrefix) {
          <span
            class="text-base-700 text-base font-semibold"
            [innerText]="formattedValue.unitWithPrefix"
          ></span>
        }
      }
    }
  </div>
</div>
