import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InputSearchComponent } from 'shared/ui/basic/input-search/input-search.component';
import { PortfolioGroupNavigationService } from 'core/portfolio-group-navigation/portfolio-group-navigation.service';
import { Observable } from 'rxjs';
import { PortfolioGroupNavigationStore } from 'core/portfolio-group-navigation/portfolio-group-navigation.store';
import { CustomIcon } from 'shared/interfaces/custom-icon.interface';

@Component({
  selector: 'ista-daytona-portfolio-group-navigation',
  templateUrl: 'portfolio-group-navigation.component.html',
  providers: [PortfolioGroupNavigationStore, PortfolioGroupNavigationService],
})
export class PortfolioGroupNavigationComponent implements OnInit {
  @ViewChild('searchInputField') searchInputField!: InputSearchComponent;
  @ViewChild('tagButton', { read: ElementRef }) tagButton!: ElementRef<HTMLDivElement>;
  selectBadgeCount$: Observable<string> = this.portfolioGroupNavigationService.selectBadgeCount$;
  selectBadgeHidden$: Observable<boolean> = this.portfolioGroupNavigationService.selectBadgeHidden$;
  selectIsEditingModeEnabled$: Observable<boolean> =
    this.portfolioGroupNavigationService.selectIsEditingModeEnabled$;
  selectShowSearchInput$: Observable<boolean> =
    this.portfolioGroupNavigationService.selectShowSearchInput$;
  selectOpenAllGroups$: Observable<boolean> =
    this.portfolioGroupNavigationService.selectOpenAllGroups$;

  badgeIcon: CustomIcon = {
    name: 'tags',
    isSvgIcon: true,
    colorClass: 'fill-base-600',
  };

  constructor(private portfolioGroupNavigationService: PortfolioGroupNavigationService) {}

  ngOnInit(): void {
    this.portfolioGroupNavigationService.selectSearchGroupParams$.subscribe();
  }

  toggleEditingMode(): void {
    this.portfolioGroupNavigationService.toggleIsEditingModeEnabledAndUpdateNavigationFinishedState();
  }

  searchDeleteButtonClicked(): void {
    this.portfolioGroupNavigationService.updateShowSearchInput(false);
    this.portfolioGroupNavigationService.updateSearchTerm('');
  }

  search(searchStr: string): void {
    this.portfolioGroupNavigationService.updateSearchTerm(searchStr);
  }

  showSearchInputField(): void {
    this.portfolioGroupNavigationService.updateShowSearchInput(true);
    setTimeout(() => {
      if (this.searchInputField?.inputField) {
        this.searchInputField.inputField.myInputField.nativeElement.focus();
      }
    });
  }

  showTagDialog(): void {
    if (this.tagButton?.nativeElement) {
      const rect = this.tagButton.nativeElement.getBoundingClientRect();
      this.portfolioGroupNavigationService.toggleTagDialog(rect.bottom, rect.left);
    }
  }
}
