import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import {
  loadSiteDetails,
  loadSiteTags,
  updateSiteTags,
} from 'app/features/portfolio/+state/portfolio.actions';
import { PortfolioHttpService } from 'app/features/portfolio/portfolio-http.service';
import {
  selectGlobalSelectedYear,
  selectSelectedEnvironmentId,
  selectSelectedGroupOrSite,
} from 'core/+state/core.selectors';
import { combineLatest, debounceTime, filter, first, mergeMap, NEVER, Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OverlayService } from 'shared/ui/overlay/overlay.service';
import { selectIsIdParamIdSite } from 'app/+state/router/reduced-route.selectors';

@Injectable()
export class PortfolioMasterdataService {
  constructor(
    private ngrxStore: Store<RootState>,
    public portfolioHttpService: PortfolioHttpService,
    private overlayService: OverlayService
  ) {}

  loadSiteDetails(): Observable<unknown> {
    return combineLatest([
      this.ngrxStore.select(selectIsIdParamIdSite),
      this.ngrxStore.select(selectSelectedEnvironmentId),
      this.ngrxStore.select(selectSelectedGroupOrSite),
      this.ngrxStore.select(selectGlobalSelectedYear),
    ]).pipe(
      debounceTime(200),
      filter(
        ([isSiteSelected, , selectedSite, selectGlobalSelectedYear]) =>
          isSiteSelected &&
          !!selectGlobalSelectedYear &&
          !!selectedSite?.id &&
          !Number.isNaN(selectedSite?.id)
      ),
      tap(([, envId, selectedSite, selectedYear]) =>
        this.ngrxStore.dispatch(
          loadSiteDetails({
            envId: envId as number,
            siteId: selectedSite?.id as number,
            year: selectedYear as number,
          })
        )
      )
    );
  }

  deleteObject(): Observable<unknown> {
    return combineLatest([
      this.ngrxStore.select(selectSelectedEnvironmentId),
      this.ngrxStore.select(selectSelectedGroupOrSite),
    ]).pipe(
      filter(([envId, selectedSite]) => !!envId && !!selectedSite),
      mergeMap(([envId, selectedSite]) =>
        this.portfolioHttpService.deletePortfolioMasterdataSite(
          envId as number,
          selectedSite?.id as number
        )
      ),
      tap(() => this.overlayService.close()),
      catchError(() => {
        this.overlayService.close();
        return NEVER;
      })
    );
  }

  loadSiteTags(): Observable<unknown> {
    return combineLatest([
      this.ngrxStore.select(selectSelectedEnvironmentId),
      this.ngrxStore.select(selectSelectedGroupOrSite),
    ]).pipe(
      filter(([envId, selectedSite]) => !!envId && !!selectedSite),
      tap(([envId, selectedSite]) => {
        this.ngrxStore.dispatch(
          loadSiteTags({
            envId: envId as number,
            siteId: selectedSite?.id as number,
          })
        );
      }),
      catchError((err) => {
        console.error('getSiteTags', err);
        return NEVER;
      })
    );
  }

  updateSiteTags(tagIds: number[]): Observable<unknown> {
    return combineLatest([
      this.ngrxStore.select(selectSelectedEnvironmentId),
      this.ngrxStore.select(selectSelectedGroupOrSite),
    ]).pipe(
      filter(([envId, selectedSite]) => !!envId && !!selectedSite),
      first(),
      tap(([envId, selectedSite]) => {
        this.ngrxStore.dispatch(
          updateSiteTags({
            envId: envId as number,
            siteId: selectedSite?.id as number,
            tags: tagIds,
          })
        );
      }),
      catchError((err) => {
        console.error('updateSiteTags', err);
        return NEVER;
      })
    );
  }
}
