<div
  [class.min-h-13]="showInputField && !hasError()"
  [class.min-h-18]="showInputField && hasError()"
  class="add-group-wrapper"
>
  <div
    (click)="showInputFieldAndSetFocus()"
    *ngIf="!showInputField"
    class="text-cta flex items-center"
    role="button"
  >
    <span class="text-sm font-semibold">{{ 'common.add-group-button-label' | translate }}</span>
    <div class="p-2 ml-auto cursor-pointer" role="button">
      <mat-icon class="icon-size-6 text-inherit">add</mat-icon>
    </div>
  </div>
  <div *ngIf="showInputField" class="absolute left-2 right-2">
    <mat-form-field class="w-full">
      <input (keydown)="onKeyDown($event)" [formControl]="groupNameInputControl" class="create-input-field"
             matInput />
      <mat-error *ngIf="hasError()">
        {{ 'group-tree-navigation.create-group-error-message' | translate }}
      </mat-error>
      <mat-icon (click)="hideInputFieldAndResetValue()" class="icon-size-5 cursor-pointer" matSuffix>
        close
      </mat-icon>
    </mat-form-field>
  </div>
</div>
