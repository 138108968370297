import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, first, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { selectSelectedLanguage } from 'core/+state/core.selectors';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  destroy$ = new Subject();

  public publicitemsPerPageLabel = this.translationService.instant(
    'paginator.itemsPerPageLabel'
  ) as string;
  public nextPageLabel = this.translationService.instant('paginator.nextPageLabel') as string;
  public previousPageLabel = this.translationService.instant(
    'paginator.previousPageLabel'
  ) as string;
  public firstPageLabel = this.translationService.instant('paginator.firstPageLabel') as string;
  public lastPageLabel = this.translationService.instant('paginator.lastPageLabel') as string;
  public itemsPerPageLabel = this.translationService.instant(
    'paginator.itemsPerPageLabel'
  ) as string;

  constructor(
    private readonly translationService: TranslateService,
    private readonly ngrxStore: Store<RootState>
  ) {
    this.ngrxStore
      .select(selectSelectedLanguage)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.setPaginatorTranslatedLabel();
      });
  }

  setPaginatorTranslatedLabel(): void {
    this.translationService
      .get([
        'paginator.itemsPerPageLabel',
        'paginator.nextPageLabel',
        'paginator.previousPageLabel',
        'paginator.firstPageLabel',
        'paginator.lastPageLabel',
      ])
      .pipe(first())
      .subscribe((translations) => {
        this.itemsPerPageLabel = translations['paginator.itemsPerPageLabel'] as string;
        this.nextPageLabel = translations['paginator.nextPageLabel'] as string;
        this.previousPageLabel = translations['paginator.previousPageLabel'] as string;
        this.firstPageLabel = translations['paginator.firstPageLabel'] as string;
        this.lastPageLabel = translations['paginator.lastPageLabel'] as string;
        this.changes.next();
      });
  }

  public getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translationService.instant('paginator.default') as string;
    }
    const amountPages = Math.ceil(length / pageSize);
    return this.translationService.instant('paginator.page', {
      current: page + 1,
      amount: amountPages,
    }) as string;
  }
}
