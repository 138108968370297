import { LandmarkInterface } from 'app/features/portfolio/portfolio-masterdata/interfaces/landmark.interface';
import { TabInterface } from 'shared/ui/basic/tab/tab.interface';

export const ADDRESS_NOT_FOUND = 'AddressNotFound';

export const LANDMARK_NOT_FOUND: LandmarkInterface = {
  name: ADDRESS_NOT_FOUND,
  lat: '0.0',
  lng: '0.0',
  label: '',
};

export const portfolioTabs: TabInterface[] = [
  {
    label: 'tabs.consumption',
    icon: {
      name: 'money_graph',
      isSvgIcon: true,
      isStrokeRelated: true,
    },
  },
  {
    label: 'tabs.masterdata',
    icon: {
      name: 'masterdata',
      isSvgIcon: true,
      isFillRelated: true,
    },
  },
];
