import { Component, Input } from '@angular/core';

import { Share } from '../../models/co2-allocation.interface';

@Component({
  selector: 'ista-daytona-co2-cost-card-stage',
  templateUrl: './co2-cost-card-stage.component.html',
  styleUrls: ['./co2-cost-card-stage.component.scss'],
})
export class Co2CostCardStageComponent {
  @Input() landlordShare!: Share | undefined;
  @Input() tenantShare!: Share | undefined;
  @Input() year = '';

  get isTenantShare(): boolean {
    if (!this.tenantShare?.percentage?.value) {
      return false;
    }

    return this.tenantShare.percentage.value === 100;
  }

  get landLordShare(): number {
    return this.landlordShare?.percentage?.value
      ? Math.ceil(this.landlordShare?.percentage?.value)
      : 0;
  }

  get tenantShareValue(): number {
    return 100 - this.landLordShare;
  }
}
