import { createReducer, on } from '@ngrx/store';
import { UNASSIGNED_SITES_GROUP_ID } from 'core/constants';
import { ReportingPeriod } from 'core/environment/reporting-period.interface';
import { GroupHelper } from 'core/groups-tree-navigation/utils/group-helper';
import { Languages } from 'core/language/language.interface';
import * as _ from 'lodash';

import { EnvironmentSettings } from '../environment-settings/interfaces';
import { Environment } from '../environment/environment.interface';
import { Group, Site, SiteOfGroup } from '../groups/groups.interface';
import { UserSettings } from '../user-settings/interfaces';
import {
  addNewGroupToGroups,
  addSitesToUnassignedSites,
  clearLoadSitesState,
  deleteGroupFromGroup,
  moveAndAddGroup,
  moveAndAddGroupToRoot,
  moveAndAddSite,
  removeSelectedSite,
  renameGroup,
  storeAvailableYears,
  storeEnvironmentReadiness,
  storeEnvironments,
  storeFrontendConfig,
  storeGroups,
  storeGroupSearchStatus,
  storeReportingPeriods,
  storeSelectedEnvironment,
  storeSelectedGroup,
  storeSelectedGroupOrSite,
  storeSelectedSite,
  storeSitesOfGroups,
  storeUserToken,
  updateEnvironmentSettings,
  updateGlobalSelectedYear,
  updateLoadSitesState,
  updateSelectedLanguage,
  updateShowNavigationMenu,
  updateSubNavigationSelectedHeadline,
  updateUnassignedSites,
  updateUserSettings,
} from './core.actions';

export interface CoreState {
  frontendConfig?: {
    keycloakUrl: string;
    keycloakClientId: string;
    keycloakMockEnabled: boolean;
  };
  userToken?: string;
  environmentReady: boolean;
  availableEnvironments: Environment[];
  selectedEnvironment?: Environment;
  hasNoEnvironments: boolean;
  yearsWithData: number[];
  reportingPeriods: ReportingPeriod[];
  groups?: Group[];
  selectedGroup?: Group;
  allowLoadSites?: boolean;
  groupSearchRunning: boolean;
  showNavigationMenu: boolean;
  subNavigationSelectedHeadline: string;
  globalSelectedYear: number | undefined;
  selectedLanguage: Languages;
  navigationSubmenuObjectIsSelected: boolean | undefined;
  selectedSite: Site | undefined;
  selectedSiteOrGroup?: Group;
  unassignedSitesGroup: Group;
  forcePortfolioGroupToReload?: boolean;
  userSettings?: UserSettings;
  environmentSettings?: EnvironmentSettings;
}

export const initialCoreState: CoreState = {
  environmentReady: false,
  availableEnvironments: [],
  selectedEnvironment: undefined,
  yearsWithData: [],
  reportingPeriods: [],
  groups: undefined,
  selectedGroup: undefined,
  allowLoadSites: true,
  groupSearchRunning: false,
  showNavigationMenu: true,
  subNavigationSelectedHeadline: '',
  globalSelectedYear: undefined,
  selectedLanguage: Languages.DE,
  environmentSettings: undefined,
  navigationSubmenuObjectIsSelected: false,
  hasNoEnvironments: false,
  selectedSite: undefined,
  selectedSiteOrGroup: undefined,
  unassignedSitesGroup: {
    groups: [],
    sites: [],
    id: UNASSIGNED_SITES_GROUP_ID,
    name: 'common.unassigned-group',
    hasSites: false,
  },
  forcePortfolioGroupToReload: false,
  userSettings: undefined,
};

const mergeSitesIntoGroupsRecursion = (
  groups: Group[],
  targetGroupId: number,
  sites: SiteOfGroup[]
) => {
  let index = 0;
  let targetGroupFound = false;
  while (index < groups.length && !targetGroupFound) {
    if (groups[index].id === targetGroupId) {
      groups[index].sites = sites;
      targetGroupFound = true;
    } else if (groups[index].groups !== undefined && groups[index].groups.length > 0) {
      mergeSitesIntoGroupsRecursion(groups[index].groups, targetGroupId, sites);
    }
    index++;
  }
};

const mergeSitesIntoGroups = (groups: Group[], targetGroupId: number, sites: SiteOfGroup[]) => {
  const result = _.cloneDeep(groups);
  mergeSitesIntoGroupsRecursion(result, targetGroupId, sites);
  return result;
};

export const coreReducer = createReducer(
  initialCoreState,
  on(storeFrontendConfig, (state, action): typeof state => ({
    ...state,
    frontendConfig: action.frontendConfig,
  })),
  on(storeUserToken, (state, action): typeof state => ({
    ...state,
    userToken: action.userToken,
  })),
  on(storeEnvironmentReadiness, (state, action): typeof state => ({
    ...state,
    environmentReady: action.environmentReady,
  })),
  on(storeEnvironments, (state, action): typeof state => ({
    ...state,
    availableEnvironments: action.availableEnvironments,
    hasNoEnvironments: !action.availableEnvironments.length,
  })),
  on(storeSelectedEnvironment, (state, action): typeof state => ({
    ...state,
    selectedEnvironment: action.selectedEnvironment,
  })),
  on(storeAvailableYears, (state, action): typeof state => {
    const isExist = action.preSelectedYear && action.yearsWithData.includes(action.preSelectedYear);
    const selectedYear = isExist
      ? action.preSelectedYear
      : action.yearsWithData[action.yearsWithData.length - 1];

    return {
      ...state,
      yearsWithData: action.yearsWithData,
      globalSelectedYear: selectedYear,
    };
  }),
  on(storeReportingPeriods, (state, action): typeof state => ({
    ...state,
    reportingPeriods: action.reportingPeriods,
  })),
  on(storeGroups, (state, action): typeof state => ({
    ...state,
    groups: action.groups,
  })),
  on(storeSelectedGroup, (state, action): typeof state => {
    const selectedGroup =
      action.selectedGroupId && state.groups
        ? GroupHelper.flattenGroups(state.groups).find((g) => g.id === action.selectedGroupId)
        : undefined;
    return {
      ...state,
      selectedGroup,
      subNavigationSelectedHeadline:
        selectedGroup?.name ?? state.subNavigationSelectedHeadline ?? '',
    };
  }),
  on(storeSitesOfGroups, (state, action): typeof state => ({
    ...state,
    groups: mergeSitesIntoGroups(state.groups ?? [], action.groupId, action.sites),
  })),
  on(updateLoadSitesState, (state, action): typeof state => ({
    ...state,
    allowLoadSites: action.state,
  })),
  on(clearLoadSitesState, (state): typeof state => ({
    ...state,
    allowLoadSites: true,
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(updateSelectedLanguage, (state, action) => ({
    ...state,
    selectedLanguage: action.selectedLanguage,
  })),
  on(updateEnvironmentSettings, (state, action) => ({
    ...state,
    environmentSettings: action.environmentSettings,
  })),
  on(storeSelectedSite, (state, action): typeof state => ({
    ...state,
    selectedSite: action.selectedSite,
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(storeSelectedGroup, (state, action): typeof state => {
    const selectedGroup = action.selectedGroupId
      ? GroupHelper.flattenGroups(state.groups ?? []).find((g) => g.id === action.selectedGroupId)
      : undefined;
    return {
      ...state,
      selectedGroup,
      subNavigationSelectedHeadline:
        selectedGroup?.name ?? state.subNavigationSelectedHeadline ?? '',
    };
  }),
  on(storeSitesOfGroups, (state, action): typeof state => ({
    ...state,
    groups: mergeSitesIntoGroups(state.groups ?? [], action.groupId, action.sites),
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(storeSitesOfGroups, (state, action): typeof state => ({
    ...state,
    groups: mergeSitesIntoGroups(state.groups ?? [], action.groupId, action.sites),
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(storeSitesOfGroups, (state, action): typeof state => ({
    ...state,
    groups: mergeSitesIntoGroups(state.groups ?? [], action.groupId, action.sites),
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(storeSitesOfGroups, (state, action): typeof state => ({
    ...state,
    groups: mergeSitesIntoGroups(state.groups ?? [], action.groupId, action.sites),
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(storeSelectedGroupOrSite, (state, action): typeof state => ({
    ...state,
    selectedSiteOrGroup: action.selectedGroupOrSite,
  })),
  on(storeSitesOfGroups, (state, action): typeof state => ({
    ...state,
    groups: mergeSitesIntoGroups(state.groups ?? [], action.groupId, action.sites),
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(removeSelectedSite, (state) => ({ ...state, selectedSite: undefined })),
  on(moveAndAddSite, (state, action) => {
    const totalGroups = _.cloneDeep([state.unassignedSitesGroup, ...(state.groups ?? [])]);
    if (totalGroups.length) {
      GroupHelper.deleteSiteFromChildGroup(totalGroups, action.site);
      GroupHelper.addSiteToGroup(totalGroups, action.site, action.targetGroup.id);
    }
    const [unassignedSitesGroup, ...groups] = totalGroups;
    return {
      ...state,
      groups,
      unassignedSitesGroup,
    };
  }),
  on(moveAndAddGroup, (state, action) => {
    let groups = _.cloneDeep(state.groups ?? []);
    if (groups.length) {
      groups = GroupHelper.addGroupToGroup(
        GroupHelper.deleteGroupFromChildGroup(groups, action.group.id),
        action.group,
        action.targetGroup.id
      );
    }
    return {
      ...state,
      groups,
    };
  }),
  on(moveAndAddGroupToRoot, (state, action) => {
    let groups = _.cloneDeep(state.groups ?? []);
    if (groups.length) {
      const groupsAfterDelete = GroupHelper.deleteGroupFromChildGroup(groups, action.group.id);
      groups = [...groupsAfterDelete, action.group];
    }
    return {
      ...state,
      groups,
    };
  }),
  on(deleteGroupFromGroup, (state, action) => {
    let groups = _.cloneDeep(state.groups ?? []);
    if (groups.length) {
      groups = GroupHelper.deleteGroupFromChildGroup(groups, action.groupId);
    }

    return {
      ...state,
      groups,
    };
  }),
  on(addSitesToUnassignedSites, (state, action) => ({
    ...state,
    unassignedSitesGroup: {
      ...state.unassignedSitesGroup,
      sites: [...action.unassignedSites, ...(state.unassignedSitesGroup.sites ?? [])],
    },
  })),
  on(updateUnassignedSites, (state, action) => ({
    ...state,
    unassignedSitesGroup: {
      ...state.unassignedSitesGroup,
      sites: action.unassignedSites,
    },
  })),
  on(addNewGroupToGroups, (state, action) => {
    if (!action.parentGroupId) {
      return {
        ...state,
        groups: [action.newGroup, ...(state.groups ?? [])],
      };
    }

    const groups = _.cloneDeep(state.groups);

    return {
      ...state,
      groups: GroupHelper.addGroupToGroup(groups ?? [], action.newGroup, action.parentGroupId),
    };
  }),
  on(updateUserSettings, (state, action) => ({
    ...state,
    userSettings: action.settings,
  })),
  on(renameGroup, (state, action) => {
    let groups = _.cloneDeep(state.groups ?? []);
    if (groups.length) {
      groups = GroupHelper.renameGroup(groups, action.groupId, action.name);
    }
    return {
      ...state,
      groups,
    };
  })
);
