<div class="card-container h-full">
  <div class="p-4 block gap-2 content-center justify-items-center h-full">
    <div class="card-color-container mb-2">
      @if (totalCostValue) {
        <ista-daytona-value-unit-card [size]="'big'" [unit]="totalCostValue.unit"
                                      [value]="totalCostValue.value"></ista-daytona-value-unit-card>
      }
    </div>
    <p class="flex gap-1 items-baseline justify-center text-base-700 text-xl font-normal">
      {{ 'dashboard.costs.co2cost.total-label-1' | translate }}
      <ista-daytona-value-unit
        [classUnit]="'text-2xl font-extrabold'"
        [classValue]="'text-2xl font-extrabold'"
        [value]="totalEmission"
      ></ista-daytona-value-unit>
      {{ 'dashboard.costs.co2cost.total-label-2' | translate }}
      <ista-daytona-value-unit
        [classUnit]="'text-2xl font-extrabold'"
        [classValue]="'text-2xl font-extrabold'"
        [value]="carbonPrice"
      ></ista-daytona-value-unit>
      {{ 'dashboard.costs.co2cost.total-label-3' | translate }}
    </p>
  </div>
</div>
