import { ComponentStore } from '@ngrx/component-store';
import { Group, SiteOfGroup } from 'core/groups/groups.interface';
import { ActiveNodeType, GroupsTreeNavigationState } from 'core/groups-tree-navigation/interfaces';
import { Injectable } from '@angular/core';
import { GroupMapper } from 'core/groups/group.mapper';

@Injectable()
export class GroupsTreeNavigationStore extends ComponentStore<GroupsTreeNavigationState> {
  readonly addNodesId = this.updater((state, nodesId: number[]) => ({
    ...state,
    expandedNodesId: [...new Set([...state.expandedNodesId, ...nodesId])],
  }));

  readonly removeExpandedNodeIds = this.updater((state, nodeIds: number[]) => ({
    ...state,
    expandedNodesId: state.expandedNodesId.filter(
      (expandedNodeId) => !nodeIds.includes(expandedNodeId)
    ),
  }));

  readonly updateActiveNode = this.updater(
    (state, { nodeId, type }: { nodeId: number; type: ActiveNodeType }) => ({
      ...state,
      activeNode: {
        type,
        id: nodeId,
      },
    })
  );

  readonly updateDraggedItem = this.updater((state, draggedItem: Group | SiteOfGroup) => ({
    ...state,
    draggedItem,
  }));

  readonly updateOpenAllGroups = this.updater((state, openAllGroups: boolean) => ({
    ...state,
    openAllGroups,
  }));

  readonly updateGroupsAndExpandedNodeIds = this.updater((state, groups: Group[]) => ({
    ...state,
    groups,
    expandedNodesId: state.openAllGroups ? GroupMapper.getGroupsIds(groups) : state.expandedNodesId,
  }));

  readonly resetDraggedItem = this.updater((state) => ({
    ...state,
    draggedItem: undefined,
  }));

  selectActiveNode$ = this.select((state) => state.activeNode);

  constructor() {
    super({
      expandedNodesId: [],
      draggedItem: undefined,
      activeNode: undefined,
      groups: [],
      openAllGroups: false,
    });
  }

  getIsNodeIdExist(nodeId: number): boolean {
    return this.get().expandedNodesId.includes(nodeId);
  }

  getExpandedNodesId(): number[] {
    return this.get().expandedNodesId;
  }

  getIsNodeActive(nodeId: number, type: ActiveNodeType): boolean {
    return this.get().activeNode?.id === nodeId && this.get().activeNode?.type === type;
  }

  getNodeActiveId(): number | undefined {
    return this.get().activeNode?.id;
  }

  getDraggedItem(): Group | SiteOfGroup | undefined {
    return this.get().draggedItem;
  }

  resetStore(): void {
    this.setState(() => ({
      activeNode: undefined,
      draggedItem: undefined,
      expandedNodesId: [],
      groups: [],
      openAllGroups: false,
    }));
  }

  getActiveNodeType(): ActiveNodeType | undefined {
    return this.get().activeNode?.type;
  }

  getOpenAllGroups(): boolean {
    return this.get().openAllGroups;
  }

  getGroups(): Group[] {
    return this.get().groups;
  }
}
