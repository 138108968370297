import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ista-daytona-add-group',
  templateUrl: 'add-group.component.html',
  styleUrls: ['add-group.component.scss'],
})
export class AddGroupComponent {
  @ViewChild('inputField') inputField!: ElementRef;
  @Input() hideIcon = false;
  @Output() createGroup = new EventEmitter<string>();
  showInputField = false;
  /* eslint-disable @typescript-eslint/unbound-method */
  groupNameInputControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.max(200),
  ]);

  constructor(private element: ElementRef<HTMLDivElement>) {}

  hasError(): boolean {
    return this.groupNameInputControl.touched && this.groupNameInputControl.invalid;
  }

  showInputFieldAndSetFocus(): void {
    this.showInputField = true;
    setTimeout(() => {
      const inputField = this.element.nativeElement.querySelector(
        '.create-input-field'
      ) as HTMLInputElement;
      if (inputField) {
        inputField.focus();
      }
    });
  }

  hideInputFieldAndResetValue(): void {
    this.showInputField = false;
    this.groupNameInputControl.reset();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.hideInputFieldAndResetValue();
    } else if (event.key === 'Enter') {
      this.groupNameInputControl.markAsTouched();
      if (this.groupNameInputControl.valid) {
        this.createGroup.emit((event.target as HTMLInputElement).value);
        this.hideInputFieldAndResetValue();
      }
    }
  }
}
