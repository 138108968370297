import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { TableSiteModule } from './ui/table-site/table-site.module';
import { UiModule } from './ui/ui.module';

@NgModule({
  imports: [CommonModule, DirectivesModule, UiModule, PipesModule],
  declarations: [],
  exports: [
    DirectivesModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    TableSiteModule,
  ],
  providers: [],
})
export class SharedModule {}
