import { ComponentStore } from '@ngrx/component-store';
import { sortFunction, Tag, TagsStoreState } from './interface';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { FormControlStatus } from '@angular/forms';

@Injectable()
export class TagsStore extends ComponentStore<TagsStoreState> {
  readonly addTag = this.updater((state, tag: Tag) => ({
    ...state,
    tags: [tag, ...state.tags],
  }));

  readonly removeTag = this.updater((state, tag: Tag) => ({
    ...state,
    tags: state.tags.filter((t) => t.id !== tag.id),
  }));

  readonly setTags = this.updater((state, tags: Tag[]) => ({
    ...state,
    tags,
  }));

  readonly updateShowCreateInputField = this.updater((state, value: boolean) => ({
    ...state,
    showCreateInputField: value,
  }));
  readonly updateIsDeleteInProgress = this.updater((state, value: boolean) => ({
    ...state,
    isDeleteInProgress: value,
  }));
  readonly updateIsEditingMode = this.updater((state, value: boolean) => ({
    ...state,
    isEditingMode: value,
  }));

  readonly updateFilteredString = this.updater((state, value: string) => ({
    ...state,
    filterString: value,
  }));

  readonly updateHighlightSaveButton = this.updater((state, highlightSaveButton: boolean) => ({
    ...state,
    highlightSaveButton,
  }));

  readonly updateTags = this.updater((state, tags: Tag[]) => {
    const tagsSet = new Set();
    const allTags = [...tags, ...state.tags];
    const filteredTags = allTags
      .filter((tag) => {
        const duplicate = tagsSet.has(tag.id);
        tagsSet.add(tag.id);
        return !duplicate;
      })
      .map((tag) => ({
        ...tag,
        lastModified: new Date().toISOString(),
      }));
    return {
      ...state,
      tags: filteredTags,
    };
  });

  readonly updateCheckedTagIds = this.updater((state, checkedTagIds: number[]) => ({
    ...state,
    checkedTagIds,
  }));

  readonly updateFormStatus = this.updater((state, formStatus: FormControlStatus) => ({
    ...state,
    formStatus,
  }));

  readonly updateSortFunction = this.updater((state, sortFunction: sortFunction) => ({
    ...state,
    sortFunction,
  }));

  readonly formStatus$: Observable<FormControlStatus> = this.select((state) => state.formStatus, {
    equal: (prev, curr) => isEqual(prev, curr),
  });

  readonly isFormInvalid$: Observable<boolean> = this.formStatus$.pipe(
    map((status) => status === 'INVALID')
  );
  readonly selectHighlightSaveButton$: Observable<boolean> = this.select(
    (state) => state.highlightSaveButton
  );

  readonly tags$: Observable<Tag[]> = this.select((state) => state.tags, {
    equal: (prev, curr) => isEqual(prev, curr),
  });
  readonly showCreateInputField$: Observable<boolean> = this.select(
    (state) => state.showCreateInputField
  );
  readonly isEditingMode$: Observable<boolean> = this.select((state) => state.isEditingMode);
  readonly isDeleteInProgress$: Observable<boolean> = this.select(
    (state) => state.isDeleteInProgress
  );

  readonly filterString$: Observable<string> = this.select((state) => state.filterString, {
    equal: (prev, curr) => prev === curr,
  });

  readonly checkedTagIds$: Observable<number[]> = this.select((state) => state.checkedTagIds, {
    equal: (prev, curr) => isEqual(prev, curr),
  });

  readonly sortFunction$: Observable<sortFunction> = this.select((state) => state.sortFunction, {
    equal: (prev, curr) => isEqual(prev, curr),
  });

  readonly sortedTagsWithFilteredString$ = this.select(
    this.tags$,
    this.filterString$,
    this.checkedTagIds$,
    this.sortFunction$,
    (tags, filterString, checkedTagIds, sortFunction) =>
      tags
        .filter(
          (tag) =>
            !filterString.length ||
            tag.name.toLowerCase().includes(this.get().filterString.toLowerCase())
        )
        .map((tag) => ({
          ...tag,
          checked: checkedTagIds.includes(tag.id),
        }))
        // eslint-disable-next-line @typescript-eslint/unbound-method
        .sort(sortFunction === 'Date' ? this.sortByDate : this.sortAlphabetically),
    { debounce: true }
  );

  constructor() {
    super({
      tags: [],
      showCreateInputField: false,
      isEditingMode: false,
      isDeleteInProgress: false,
      highlightSaveButton: false,
      filterString: '',
      checkedTagIds: [],
      formStatus: 'VALID',
      sortFunction: 'Alphabetical',
    });
  }

  isNameAlreadyExist(name: string): boolean {
    return !!this.get().tags.find((tag) => tag.name === name);
  }

  resetState(): void {
    this.setState((state) => ({
      tags: [],
      showCreateInputField: false,
      isEditingMode: false,
      isDeleteInProgress: false,
      highlightSaveButton: false,
      filterString: '',
      checkedTagIds: [],
      formStatus: 'VALID',
      sortFunction: state.sortFunction,
    }));
  }

  private sortAlphabetically(a: Tag, b: Tag): number {
    return a.name.localeCompare(b.name);
  }

  private sortByDate(a: Tag, b: Tag): number {
    return new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime();
  }
}
