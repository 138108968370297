import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { Environment } from './environment.interface';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { selectAvailableEnvironment, selectSelectedEnvironment } from '../+state/core.selectors';
import { storeSelectedEnvironment } from '../+state/core.actions';

@Component({
  selector: 'ista-daytona-environment-container',
  templateUrl: './environment-container.component.html',
})
export class EnvironmentContainerComponent implements OnInit {
  public envs$: Observable<Environment[]> = EMPTY;
  public selectedEnv$: Observable<Environment | undefined> = EMPTY;

  constructor(private readonly ngrxStore: Store<RootState>) {}

  ngOnInit(): void {
    this.envs$ = this.ngrxStore.select(selectAvailableEnvironment);
    this.selectedEnv$ = this.ngrxStore.select(selectSelectedEnvironment);
  }

  public onSelectChange(env: Environment): void {
    this.ngrxStore.dispatch(storeSelectedEnvironment({ selectedEnvironment: env }));
  }
}
