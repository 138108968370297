import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthModule } from './auth/auth.module';
import { EnvironmentModule } from './environment/environment.module';
import { LoadStatusIntereptor } from './load-status/load-status.interceptor';
import { ErrorCatchingInterceptor } from './http-error.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { HttpTokenAuthorizationInterceptor } from './http-token-authorization.interceptor';
import { GlobalYearSelectionComponent } from 'core/global-year-selection/global-year-selection.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StickyHeaderComponent } from 'core/sticky-header/sticky-header.component';
import { PipesModule } from 'shared/pipes/pipes.module';
import { DirectivesModule } from 'shared/directives/directives.module';
import { FilterModule } from 'shared/ui/filter/filter.module';
import { BasicModule } from 'shared/ui/basic/basic.module';
import { NgChartsModule } from 'ng2-charts';
import { TabModule } from 'shared/ui/basic/tab/tab.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { PortfolioGroupNavigationComponent } from 'core/portfolio-group-navigation/portfolio-group-navigation.component';
import { ButtonModule } from 'shared/ui/basic/button/button.module';
import { GroupsTreeNavigationModule } from 'core/groups-tree-navigation/groups-tree-navigation.module';
import { TooltipModule } from 'shared/ui/basic/tooltip/tooltip.module';
import { InputSearchModule } from 'shared/ui/basic/input-search/input-search.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { UiBadgeVerticallyCenteredDirective } from '@ista-ui/angular';
import { MatBadge } from '@angular/material/badge';
import { BadgeButtonComponent } from 'shared/ui/basic/badge-button/badge-button.component';

@NgModule({
  imports: [
    CommonModule,
    EnvironmentModule,
    AuthModule.forRoot(),
    HttpClientModule,
    TranslateModule,
    MatSelectModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
    FilterModule,
    BasicModule,
    NgChartsModule,
    TabModule,
    MatTreeModule,
    MatButtonModule,
    RouterLink,
    ButtonModule,
    GroupsTreeNavigationModule,
    TooltipModule,
    InputSearchModule,
    MatTooltipModule,
    MatInputModule,
    MatIconModule,
    GlobalYearSelectionComponent,
    ReactiveFormsModule,
    UiBadgeVerticallyCenteredDirective,
    MatBadge,
    BadgeButtonComponent,
  ],
  declarations: [
    StickyHeaderComponent,
    PortfolioGroupNavigationComponent,
  ],
  exports: [
    EnvironmentModule,
    GlobalYearSelectionComponent,
    StickyHeaderComponent,
    PortfolioGroupNavigationComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadStatusIntereptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenAuthorizationInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
