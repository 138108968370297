import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize, map, mergeMap, shareReplay, takeUntil, tap } from 'rxjs';
import { TagDialogComponent } from 'core/portfolio-group-navigation/tag-dialog/tag-dialog.component';
import { PortfolioGroupNavigationStore } from 'core/portfolio-group-navigation/portfolio-group-navigation.store';
import { updatePossibleEditingPortfolioNavigationFinishedState } from 'app/features/portfolio/+state/portfolio.actions';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { GroupService } from 'core/groups/group.service';

@Injectable()
export class PortfolioGroupNavigationService {
  selectBadgeCount$ = this.portfolioGroupNavigationStore.selectBadgeCount$.pipe(shareReplay(1));
  selectBadgeHidden$ = this.selectBadgeCount$.pipe(map((count) => parseInt(count, 10) === 0));
  selectShowSearchInput$ = this.portfolioGroupNavigationStore.selectShowSearchInput$;
  selectIsEditingModeEnabled$ = this.portfolioGroupNavigationStore.selectIsEditingModeEnabled$;
  selectSearchGroupParams$ = this.portfolioGroupNavigationStore.selectSearchGroupParams$.pipe(
    mergeMap((searchParam) => {
      if (searchParam.searchTerm || searchParam.tagIds?.length) {
        return this.groupService.searchGroups(searchParam);
      } else {
        return this.groupService.getInitialGroupsState();
      }
    }),
    takeUntil(this.portfolioGroupNavigationStore.destroy$)
  );
  selectOpenAllGroups$ = this.portfolioGroupNavigationStore.selectSearchGroupParams$.pipe(
    map(({ searchTerm, tagIds }) => !!searchTerm || !!tagIds?.length)
  );
  dialogRef?: MatDialogRef<TagDialogComponent, TagDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private readonly ngrxStore: Store<RootState>,
    private readonly groupService: GroupService,
    private portfolioGroupNavigationStore: PortfolioGroupNavigationStore
  ) {}

  toggleTagDialog(top: number, left: number): void {
    if (!this.dialogRef) {
      this.openTagDialog(top, left);
    } else {
      this.dialogRef.close();
    }
  }

  openTagDialog(top: number, left: number): void {
    this.dialogRef = this.dialog.open(TagDialogComponent, {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      data: {
        initiallySelectedIds: this.portfolioGroupNavigationStore.getTagsIds(),
      },
      position: {
        top: `${top}px`,
        left: `${left}px`,
      },
    });

    this.dialogRef
      .afterClosed()
      .pipe(
        tap(() => this.updateTagsIds(this.dialogRef?.componentInstance.selectedIds ?? [])),
        finalize(() => {
          this.dialogRef = undefined;
        })
      )
      .subscribe();
  }

  updateTagsIds(ids: number[]): void {
    this.portfolioGroupNavigationStore.updateSelectedTagsIds(ids);
  }

  updateSearchTerm(searchTerm: string): void {
    this.portfolioGroupNavigationStore.updateSearchTerm(searchTerm);
  }

  updateShowSearchInput(showSearchInput: boolean): void {
    this.portfolioGroupNavigationStore.updateShowSearchInput(showSearchInput);
  }

  updatePossibleEditingPortfolioNavigationFinishedState(isEditingModeEnabled: boolean): void {
    this.ngrxStore.dispatch(
      updatePossibleEditingPortfolioNavigationFinishedState({
        possibleState: isEditingModeEnabled ? 0 : 2,
      })
    );
  }

  updateIsEditingModeEnabled(isEditingModeEnabled: boolean): void {
    this.portfolioGroupNavigationStore.updateIsEditingModeEnabled(isEditingModeEnabled);
  }

  getIsEditingModeEnabled(): boolean {
    return this.portfolioGroupNavigationStore.getIsEditingModeEnabled();
  }

  toggleIsEditingModeEnabledAndUpdateNavigationFinishedState(): void {
    const currentValue = this.portfolioGroupNavigationStore.getIsEditingModeEnabled();
    this.updatePossibleEditingPortfolioNavigationFinishedState(!currentValue);
    this.updateIsEditingModeEnabled(!currentValue);
  }
}
