import { ComponentStore } from '@ngrx/component-store';
import { PortfolioGroupNavigationState } from './interfaces';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { GroupSearch } from 'core/groups/groups.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class PortfolioGroupNavigationStore extends ComponentStore<PortfolioGroupNavigationState> {
  readonly updateSelectedTagsIds = this.updater((state, tagsSelectedIds: number[]) => ({
    ...state,
    tagsSelectedIds,
  }));

  readonly updateSearchTerm = this.updater((state, searchTerm: string) => ({
    ...state,
    searchTerm,
  }));

  readonly updateShowSearchInput = this.updater((state, showSearchInput: boolean) => ({
    ...state,
    showSearchInput,
  }));

  readonly updateIsEditingModeEnabled = this.updater((state, isEditingModeEnabled: boolean) => ({
    ...state,
    isEditingModeEnabled,
  }));

  selectSelectedTagsIds$ = this.select((state) => state.tagsSelectedIds, {
    equal: (prev, curr) => isEqual(prev, curr),
  });

  selectBadgeCount$: Observable<string> = this.select(
    this.selectSelectedTagsIds$,
    (ids) => `${ids.length}`,
    {
      equal: (prev, curr) => isEqual(prev, curr),
    }
  );

  selectSearchTerm$ = this.select((state) => state.searchTerm);

  selectShowSearchInput$ = this.select((state) => state.showSearchInput);

  selectSearchGroupParams$: Observable<GroupSearch> = this.select(
    this.selectSearchTerm$,
    this.selectSelectedTagsIds$,
    (searchTerm, tagIds) => ({
      tagIds,
      searchTerm,
    })
  );

  selectIsEditingModeEnabled$ = this.select((state) => state.isEditingModeEnabled);

  constructor() {
    super({
      tagsSelectedIds: [],
      searchTerm: '',
      showSearchInput: false,
      isEditingModeEnabled: false,
    });
  }

  getTagsIds(): number[] {
    return this.get().tagsSelectedIds;
  }

  getIsEditingModeEnabled(): boolean {
    return this.get().isEditingModeEnabled;
  }
}
