import {
  AfterViewInit,
  booleanAttribute,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Group, SiteOfGroup } from 'core/groups/groups.interface';
import { Subject, takeUntil } from 'rxjs';
import { GroupsTreeNavigationStore } from 'core/groups-tree-navigation/groups-tree-navigation.store';
import { GroupsTreeNavigationService } from 'core/groups-tree-navigation/groups-tree-navigation.service';
import { GroupsTreeNavigationHttpService } from 'core/groups-tree-navigation/groups-tree-navigation-http.service';
import { UNASSIGNED_SITES_GROUP_ID } from 'core/constants';
import { ActiveNodeType } from 'core/groups-tree-navigation/interfaces';

@Component({
  selector: 'ista-daytona-groups-tree-navigation',
  templateUrl: 'groups-tree-navigation.component.html',
  styleUrls: ['groups-tree-navigation.component.scss'],
  providers: [
    GroupsTreeNavigationHttpService,
    GroupsTreeNavigationStore,
    GroupsTreeNavigationService,
  ],
})
export class GroupsTreeNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input({ transform: booleanAttribute }) enableEditingMode = false;
  @Input({ transform: booleanAttribute }) highlightEmptyGroups = false;
  dropZoneGroup?: Group;
  treeControl = new NestedTreeControl<Group>((node) => node.groups);
  dataSource = new MatTreeNestedDataSource<Group>();
  private destroy$ = new Subject<void>();

  constructor(public groupsTreeNavigationService: GroupsTreeNavigationService) {}

  @Input({ transform: booleanAttribute }) set openAllGroups(openAllGroups: boolean) {
    this.groupsTreeNavigationService.updateOpenAllGroups(openAllGroups);
  }

  ngOnInit(): void {
    this.groupsTreeNavigationService.setGroupOrSiteId();
    this.groupsTreeNavigationService
      .listenToEnvironmentChangesAndNavigateToPortfolio()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  isGroupUnassignedGroup(group: Group): boolean {
    return group.id === UNASSIGNED_SITES_GROUP_ID;
  }

  shouldShowUnassignedGroup(group: Group): boolean {
    return this.isGroupUnassignedGroup(group) && (this.enableEditingMode || !!group.sites?.length);
  }

  shouldHighlightGroup(group: Group): boolean {
    return this.highlightEmptyGroups && !this.enableEditingMode && !group.sites?.length;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.groupsTreeNavigationService
      .checkIfISiteAndGetSiteHierarchyAndSetHierarchyGroups()
      .subscribe();
    this.groupsTreeNavigationService
      .listenToGroupChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups) => (this.dataSource.data = groups));
    this.groupsTreeNavigationService
      .listenToPortfolioUrlChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  shouldEnableOpening(group: Group): boolean {
    return (
      this.enableEditingMode ||
      this.shouldShowUnassignedGroup(group) ||
      group.hasSites ||
      !!group.groups.length
    );
  }

  loadSites(group: Group): void {
    this.groupsTreeNavigationService.loadSites(group);
  }

  deleteGroup({ x, y }: { x: number; y: number }, group: Group): void {
    this.groupsTreeNavigationService.deleteGroup(group, y, x).subscribe();
  }

  deleteSite(
    { event, site }: { event: { x: number; y: number }; site: SiteOfGroup },
    parentGroup: Group
  ): void {
    this.groupsTreeNavigationService.deleteSite(event.y, event.x, site, parentGroup).subscribe();
  }

  toggleNodeAndLoadSites(group: Group): void {
    this.groupsTreeNavigationService.toggleNode(group);
    if (!this.isGroupUnassignedGroup(group)) {
      this.loadSites(group);
    }
  }

  navigateToSite(site: SiteOfGroup): void {
    this.groupsTreeNavigationService.navigateToSite(site);
  }

  createGroup(name: string, parentId?: number): void {
    this.groupsTreeNavigationService.createGroup(name, parentId).subscribe();
  }

  navigateToGroup(group: Group): void {
    this.groupsTreeNavigationService.navigateToGroup(group);
  }

  dragSiteStart(event: DragEvent, site: SiteOfGroup, parentGroupId: number): void {
    this.groupsTreeNavigationService.dragItemStart(event, site, 'site', parentGroupId);
  }

  dragGroupStart(event: DragEvent, group: Group): void {
    if (!this.isGroupUnassignedGroup(group)) {
      this.groupsTreeNavigationService.dragItemStart(event, group, 'group');
    }
  }

  showInEditingModeAndForNotUnassignedGroup(group: Group): boolean {
    return this.enableEditingMode && !this.isGroupUnassignedGroup(group);
  }

  showInReadonlyModeAndForNotUnassignedGroup(group: Group): boolean {
    return !this.enableEditingMode && !this.isGroupUnassignedGroup(group);
  }

  handleDrop(event: DragEvent, node: Group): void {
    this.groupsTreeNavigationService.handleDrop(event, node);
    this.dropZoneGroup = undefined;
  }

  dragend(): void {
    this.dropZoneGroup = undefined;
  }

  isExpanded(group: Group): boolean {
    return this.groupsTreeNavigationService.isExpanded(group);
  }

  isNodeActive(nodeId: number, type: ActiveNodeType): boolean {
    return this.groupsTreeNavigationService.getIsNodeActive(nodeId, type);
  }

  handleDragEnter($event: DragEvent, node: Group): void {
    this.dropZoneGroup = node;
  }

  handleDragLeave(): void {
    this.dropZoneGroup = undefined;
  }

  handleDragOver(event: DragEvent, node: Group): void {
    this.dropZoneGroup = node;
    this.groupsTreeNavigationService.handleDragOver(event);
  }

  handleSiteDragOver({ event, parentGroup }: { event: DragEvent; parentGroup: Group }): void {
    this.dropZoneGroup = parentGroup;
    this.groupsTreeNavigationService.handleDragOver(event);
  }
}
