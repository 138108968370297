import { Component, Input } from '@angular/core';
import { ColorStyle } from 'shared/models/color-style.enum';
import { OverlayService } from 'shared/ui/overlay/overlay.service';
import { PortfolioMasterdataService } from 'app/features/portfolio/portfolio-masterdata/portfolio-masterdata.service';

@Component({
  selector: 'ista-daytona-delete-object',
  templateUrl: './delete-object.component.html',
  providers: [PortfolioMasterdataService],
})
export class DeleteObjectComponent {
  @Input() showDeleteObjectCard = false;

  selectedObject = '';
  protected readonly colorStyle = ColorStyle;

  constructor(
    private overlayService: OverlayService,
    public readonly portfolioMasterdataService: PortfolioMasterdataService
  ) {}

  closeWindow(): void {
    this.overlayService.close();
  }

  deleteObject(): void {
    this.portfolioMasterdataService.deleteObject().subscribe();
  }
}
