import { Injectable } from '@angular/core';
import { TagsHttpService } from './tags-http.service';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import {
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  first,
  from,
  map,
  mergeMap,
  NEVER,
  Observable,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import { sortFunction, Tag } from './interface';
import { selectSelectedEnvironmentId } from '../+state/core.selectors';
import { TagsStore } from 'core/tags/tags.store';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmDialogComponent } from 'core/dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import { catchError } from 'rxjs/operators';
import { DeleteRevertDialogComponent } from 'core/dialogs/delete-revert-dialog/delete-revert-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControlStatus } from '@angular/forms';

@Injectable()
export class TagsService {
  constructor(
    private tagHttpService: TagsHttpService,
    private ngrxStore: Store<RootState>,
    private tagsStore: TagsStore,
    private dialog: MatDialog,
    private toastService: ToastrService,
    private translateService: TranslateService
  ) {}

  showDeleteConfirmDialog(itemName: string, top: number, left: number): Observable<boolean> {
    const dialog = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {
        itemName,
      },
      hasBackdrop: false,
      position: {
        top: `${top}px`,
        left: `${left}px`,
      },
      panelClass: '-translate-x-2/4',
    });
    return dialog.afterClosed() as Observable<boolean>;
  }

  showDeleteRevertDialog(itemName: string, top: number, left: number): Observable<boolean> {
    const dialog = this.dialog.open(DeleteRevertDialogComponent, {
      data: {
        itemName,
      },
      hasBackdrop: false,
      position: {
        top: `${top}px`,
        left: `${left}px`,
      },
      panelClass: '-translate-x-2/4',
    });
    dialog
      .afterOpened()
      .pipe(delay(3000))
      .subscribe(() => dialog.close(false));
    return dialog.afterClosed() as Observable<boolean>;
  }

  deleteTag(tag: Tag, top: number, left: number): Observable<unknown> {
    this.tagsStore.updateIsDeleteInProgress(true);
    return this.showDeleteConfirmDialog(tag.name, top, left).pipe(
      tap((isConfirmed) => {
        if (!isConfirmed) {
          this.tagsStore.updateIsDeleteInProgress(false);
        }
      }),
      filter(Boolean),
      mergeMap(() =>
        this.showDeleteRevertDialog(tag.name, top, left).pipe(map((shouldRevert) => !shouldRevert))
      ),
      tap(() => this.tagsStore.updateIsDeleteInProgress(false)),
      filter(Boolean),
      tap(() => {
        this.tagsStore.removeTag(tag);
      }),
      mergeMap(() => this.ngrxStore.select(selectSelectedEnvironmentId).pipe(first())),
      filter(Boolean),
      mergeMap((envId) => this.tagHttpService.deleteTag(envId, tag.id)),
      tap(() => {
        this.showSuccessfulMessage(
          this.translateService.instant('tags.tag-delete-successful-message', {
            tagName: tag.name,
          }) as string
        );
      }),
      catchError((err) => {
        console.error('deleteTag', err);
        this.tagsStore.updateIsDeleteInProgress(false);
        this.showErrorOccurredMessage();
        return NEVER;
      })
    );
  }

  createTag(name: string): Observable<Tag> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      first(),
      filter(Boolean),
      mergeMap((envId) => this.tagHttpService.createTag(envId, name)),
      tap((tag) => {
        this.tagsStore.addTag(tag);
        this.showSuccessfulMessage(
          this.translateService.instant('tags.tag-create-successful-message', {
            tagName: tag.name,
          }) as string
        );
      }),
      catchError((err) => {
        console.error('createTag', err);
        this.showErrorOccurredMessage();
        return NEVER;
      })
    );
  }

  listenToEnvironmentChangeAndGetAllTags(): Observable<Tag[]> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      filter(Boolean),
      distinctUntilChanged(),
      concatMap((value, index) =>
        index !== 0 ? of(value).pipe(tap(() => this.tagsStore.resetState())) : of(value)
      ),
      mergeMap((envId) => this.tagHttpService.getAllTags(envId)),
      tap((tags) => this.tagsStore.setTags(tags)),
      catchError((err) => {
        console.error('listenToEnvironmentChangeAndGetAllTags', err);
        this.showErrorOccurredMessage();
        return NEVER;
      })
    );
  }

  updateShowCreateInputField(showCreateInputField: boolean): void {
    this.tagsStore.updateShowCreateInputField(showCreateInputField);
  }

  updateHighlightSaveButton(highlightSaveButton: boolean): void {
    this.tagsStore.updateHighlightSaveButton(highlightSaveButton);
  }

  updateIsEditingMode(isEditingMode: boolean): void {
    this.tagsStore.updateIsEditingMode(isEditingMode);
  }

  updateSearchString(searchString: string): void {
    this.tagsStore.updateFilteredString(searchString);
  }

  updateFormStatus(formStatus: FormControlStatus): void {
    this.tagsStore.updateFormStatus(formStatus);
  }

  updateTags(tags: Tag[]): Observable<Tag> {
    return of(tags.filter((tag) => !this.tagsStore.isNameAlreadyExist(tag.name))).pipe(
      filter((filteredTags) => !!filteredTags.length),
      withLatestFrom(this.ngrxStore.select(selectSelectedEnvironmentId)),
      filter(([, envId]) => !!envId),
      tap(([filteredTags]) => this.updateTagsLocally(filteredTags)),
      mergeMap(([filteredTags, envId]) => this.updateTagsInBE(envId as number, filteredTags)),
      catchError((err) => {
        console.error('updateTags', err);
        this.showErrorOccurredMessage();
        return NEVER;
      })
    );
  }

  updateTagsLocally(tags: Tag[]): void {
    this.tagsStore.updateTags(tags);
  }

  updateTagsInBE(envId: number, tags: Tag[]): Observable<Tag> {
    return from(tags).pipe(mergeMap((tag) => this.updateTag(envId, tag)));
  }

  updateTag(envId: number, tag: Tag): Observable<Tag> {
    return this.tagHttpService.updateTag(envId, tag.id, tag.name);
  }

  updateCheckedTagIds(checkedTagIds: number[]): void {
    this.tagsStore.updateCheckedTagIds(checkedTagIds);
  }

  updateSortFunction(sortFunction: sortFunction): void {
    this.tagsStore.updateSortFunction(sortFunction);
  }

  private showSuccessfulMessage(message: string): void {
    this.toastService.show(
      message,
      '',
      {
        timeOut: 5000,
        tapToDismiss: true,
        payload: {
          showIcon: true,
          customFontIcon: 'info',
        },
      },
      'success'
    );
  }

  private showErrorOccurredMessage(): void {
    this.toastService.show(
      this.translateService.instant('tags.error-occurred') as string,
      '',
      {
        tapToDismiss: true,
        payload: {
          showIcon: true,
          customFontIcon: 'info',
        },
      },
      'error'
    );
  }
}
