<div class="card-container h-full">
  <div class="p-4 block gap-2 content-center justify-items-center">
    <ista-daytona-card-headline
      [label]="'dashboard.costs.co2cost.distribution'"
      class="block mb-2"
    ></ista-daytona-card-headline>
    <ista-daytona-bar-horizontal-chart-mini
      [color]="modelStageColor"
      [data]="modelStageData"
      [title]="'dashboard.costs.co2cost.distribution-object-count'"
      maxLabel="&#8805;52"
      minLabel="&#60;12"
    ></ista-daytona-bar-horizontal-chart-mini>
  </div>
</div>
