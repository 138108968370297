<div *ngIf="data" [class.p8-10]="data.unitText" class="p-6 border rounded border-base-200 relative">
  <div class="inner_content">
    <div class="header">
      <ista-daytona-card-headline [label]="data.header" data-cy="bar-card--header"></ista-daytona-card-headline>
    </div>
    @if (data.infoText) {
      <div class="mt-4">
        <p
          [innerHTML]="data.infoText || '' | translate"
          class="infotext text-base text-base-700 font-normal"
          data-cy="bar-card--infoText"
        ></p>
      </div>
    }
    <div #barScreen class="bar-container mt-4">
      <div *ngIf="filteredBarItems.length" class="relative h-[215px] bg-white p-4 border border-base-200">
        <div
          *ngFor="let scale of scaleData.slice(0, -1); let index = index"
          [class.border-l-0]="index === 0"
          [style.left]="getPosition(index)" class="absolute w-[1px] top-0 border-l border-l-base-200 z-10 h-full"
        ></div>
        <div
          *ngFor="
            let bar of filteredBarItems;
            let first = first;
            let last = last;
            let index = index
          "
          [class.hover:text-primary]="!!deepLinkingTargetId"
          [customParamsValues]="deepLinkingCustomParams"
          [redirectUrl]="deepLinkingRedirectBaseUrl + bar.id"
          [style.background]="color"
          [style.top.px]="getBarTop(index)"
          [style.width]="getBarWidth(bar)"
          [targetId]="deepLinkingTargetId"
          [title]="bar.value.value"
          class="h-6 absolute left-0 z-20 text-base-900"
          data-cy="bar-card--item"
          deepLinkingSender
        >
          <span
            [innerText]="bar.label"
            [style.width]="offsetWidth"
            class="ml-3 text-base font-extrabold "
          ></span>
        </div>
      </div>
      <div *ngIf="filteredBarItems.length" class="mt-2 flex relative justify-center mb-6">
        <div
          *ngFor="let scale of scaleData; let index = index"
          [innerText]="scale"
          [style.left]="getScaleLabelPosition(index)"
          class="text-sm font-semibold text-base-500 h-6 absolute"
          data-cy="bar-card--scale"
        ></div>
      </div>
    </div>
  </div>
  @if (data.unitText) {
    <span class="block text-right mt-8 text-base-500 text-sm font-extrabold">
      {{ data.unitText | translate }}
    </span>
  }
</div>
