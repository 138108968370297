import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { ReportingPeriod } from 'core/environment/reporting-period.interface';
import { environment } from 'environments/environment';
import { filter, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';

import {
  storeAvailableYears,
  storeEnvironmentReadiness,
  storeEnvironments,
  storeReportingPeriods,
  storeSelectedEnvironment,
} from '../+state/core.actions';
import {
  selectEnvironmentIdQueryParam,
  selectYearQueryParam,
} from '../../+state/router/reduced-route.selectors';
import { selectSelectedEnvironment } from '../+state/core.selectors';
import { ErrorHandlerService } from '../error-handling/error-handler.service';
import { GroupService } from '../groups/group.service';
import { RequestTrackingService } from '../request-tracking.service';
import { Environment } from './environment.interface';

// @TODO please, please, please write unittest
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService extends DestroyAbstractService {
  readonly baseUrl = environment?.apiUrl;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly ngrxStore: Store<RootState>,
    private readonly _httpClient: HttpClient,
    private readonly requestTrackingService: RequestTrackingService,
    private readonly _errorHandler: ErrorHandlerService,
    private readonly groupService: GroupService
  ) {
    super();
    this.ngrxStore
      .select(selectSelectedEnvironment)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((selectedEnvironment) => {
        this.loadYearsWithData(selectedEnvironment);
        this.groupService.loadGroupsOfEnvironment(selectedEnvironment);
      });
  }

  public async updateYearsAndGroups(): Promise<void> {
    const selectedEnvironment = await firstValueFrom(
      this.ngrxStore.select(selectSelectedEnvironment)
    );
    try {
      await this.loadYearsWithData(selectedEnvironment);
      await this.groupService.loadGroupsOfEnvironment(selectedEnvironment);
    } catch {
      Promise.reject();
    }
  }

  public async loadEnvironments(): Promise<void> {
    this.ngrxStore.dispatch(storeEnvironmentReadiness({ environmentReady: false }));
    const requestId = this.requestTrackingService.registerRequest('loadEnvironments');
    try {
      const result = await firstValueFrom(
        this._httpClient.get<Environment[]>(`${this.baseUrl}/api/environments`)
      );

      // If there is a newer environments request, discard the result
      if (this.requestTrackingService.isRequestStillValid('loadEnvironments', requestId)) {
        this.ngrxStore.dispatch(storeEnvironments({ availableEnvironments: result }));
        if (result.length > 0) {
          const environmentIdQueryParam = await firstValueFrom(
            this.ngrxStore.select(selectEnvironmentIdQueryParam)
          );
          const index = result.findIndex((env) => env.id === environmentIdQueryParam);
          const env = index > -1 ? result[index] : result[0];
          this.ngrxStore.dispatch(
            storeSelectedEnvironment({
              selectedEnvironment: env,
            })
          );
        }
      }
    } catch (error) {
      this._errorHandler.handleError(error, 'LOAD_ENVIRONMENTS');
    }
  }

  public async loadYearsWithData(selectedEnvironment?: Environment): Promise<void> {
    if (selectedEnvironment) {
      this.ngrxStore.dispatch(storeEnvironmentReadiness({ environmentReady: false }));
      const requestId = this.requestTrackingService.registerRequest('loadYearsWithData');
      try {
        const result = await firstValueFrom(
          this._httpClient.get<number[]>(
            `${this.baseUrl}/api/${selectedEnvironment.id}/time-series/years-with-data`
          )
        );

        if (this.requestTrackingService.isRequestStillValid('loadYearsWithData', requestId)) {
          const yearQueryParam = await firstValueFrom(this.ngrxStore.select(selectYearQueryParam));
          this.ngrxStore.dispatch(storeAvailableYears({ yearsWithData: result }));

          this.ngrxStore.dispatch(
            storeAvailableYears({ yearsWithData: result, preSelectedYear: yearQueryParam })
          );
          this.ngrxStore.dispatch(storeEnvironmentReadiness({ environmentReady: true }));
        }
      } catch (error) {
        this._errorHandler.handleError(error, 'LOAD_YEARS_WITH_DATA');
      }
    }
  }

  async loadReportingPeriods(): Promise<void> {
    const selectedEnvironment = await firstValueFrom(
      this.ngrxStore.select(selectSelectedEnvironment)
    );
    if (selectedEnvironment) {
      try {
        const requestId = this.requestTrackingService.registerRequest('loadReportingPeriods');
        const result = await firstValueFrom(
          this._httpClient.get<ReportingPeriod[]>(
            `${this.baseUrl}/api/${selectedEnvironment.id}/time-series/report-periods`
          )
        );

        if (this.requestTrackingService.isRequestStillValid('loadReportingPeriods', requestId)) {
          this.ngrxStore.dispatch(storeReportingPeriods({ reportingPeriods: result }));
        }
      } catch (error) {
        this._errorHandler.handleError(error, 'LOAD_REPORTING_PERIODS');
      }
    }
  }
}
