import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ChartData, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DeepLinkingIds } from 'core/deep-linking/deep-linking.interface';
import { BehaviorSubject, combineLatest, ReplaySubject, takeUntil } from 'rxjs';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';

import { ChartScaleHelper } from '../../helper/chart-scale-helper';
import { ColorHelper } from '../../helper/color-helper';
import { FilterDateRange } from '../filter/filter-tab.interface';
import { BarCardData, BarItem } from './bar-card.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-bar-card',
  templateUrl: './bar-card.component.html',
  styleUrls: ['./bar-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarCardComponent extends DestroyAbstractService implements AfterViewInit {
  @ViewChild('barScreen') barScreen!: ElementRef<HTMLElement>;
  @Input() color = ColorHelper.get('cost-light');
  @Input() deepLinkingCustomParams: string[] = [];
  @Input() deepLinkingRedirectBaseUrl = '';
  @Input() deepLinkingTargetId?: DeepLinkingIds;
  public actualYear$ = new BehaviorSubject<FilterDateRange>({
    from: '',
    until: '',
  });
  public data$ = new ReplaySubject<BarCardData>();
  public scaleData: number[] = [];
  public scaleStep = 0;
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];
  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [],
  };
  public maxBarValue = 0;
  public filteredBarItems: BarItem[] = [];
  public offsetWidth = '100%';
  protected readonly DeepLinkingIds = DeepLinkingIds;
  private scaleStepCount = 10;
  private readonly margin = 16;
  private readonly height = 24;

  constructor(private readonly _ref: ChangeDetectorRef) {
    super();
    combineLatest([this.actualYear$, this.data$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([year, data]) => {
        this.filteredBarItems = data?.bars?.filter((item) => item.year === year.from);

        if (this.filteredBarItems.length > 0) {
          this.calculate();
          this.onResize();
        }
      });
  }

  private _data!: BarCardData;

  get data(): BarCardData {
    return this._data;
  }

  @Input() set data(value: BarCardData) {
    this._data = value;
    this.data$.next(value);
  }

  private _selectedYear: FilterDateRange | undefined;

  get selectedYear(): FilterDateRange | undefined {
    return this._selectedYear;
  }

  @Input() set selectedYear(year: FilterDateRange | undefined) {
    if (year) {
      this._selectedYear = year;
      this.actualYear$.next(year);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.offsetWidth = `${this.barScreen?.nativeElement?.offsetWidth - 15 || '50'}px`;
    this._ref.detectChanges();
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  public getBarWidth(bar: BarItem): string {
    const index = this._data?.bars.findIndex(
      (item) => item.label === bar.label && item.value.value === bar.value.value
    );

    let value =
      (Math.abs(this._data?.bars[index]?.value?.value || 0) / this.maxBarValue) *
      (this.scaleStepCount * 10);

    if (this.scaleStepCount !== 10) {
      value = (Math.abs(this._data?.bars[index]?.value?.value || 0) / this.maxBarValue) * 100;
    }

    return value.toString() + '%';
  }

  public getValueOfSelectedYear(): number {
    return Math.abs(
      this.data.bars.find((item) => item.year === this.actualYear$.value.from)?.value?.value || 0
    );
  }

  public getPosition(index: number): string {
    let value = (100 / this.scaleStepCount) * index;
    if (value === 100) {
      value -= 2;
    }
    return `${value}%`;
  }

  public getScaleLabelPosition(index: number): string {
    return `calc(${this.getPosition(index)} - 2px)`;
  }

  public getBarTop(index: number): number {
    return index * this.height + (index + 1) * this.margin;
  }

  public getTotalHeight(): number {
    const len = this.filteredBarItems.length;

    return (this.margin + this.height) * len + this.margin;
  }

  public getShadowTop(): number {
    const len = this.filteredBarItems.length;
    return this.getBarTop(len);
  }

  public calculate(): void {
    const values = this.filteredBarItems.map((item) => item.value?.value || 0);
    const result = ChartScaleHelper.calculateScaling(9, 5, values);
    if (result.maxValue && result.numberOfSteps) {
      this.scaleStepCount = result.numberOfSteps;
      this.scaleStep = result.maxValue / result.numberOfSteps;
      this.scaleData = [];
      for (let i = 0; i <= result.numberOfSteps; i++) {
        this.scaleData.push(i * this.scaleStep);
      }
      this.maxBarValue = result.maxValue;
    }
  }
}
