<mat-expansion-panel (opened)="isOpened" *ngFor="let label of labels" [expanded]="isOpened"
                     [ngClass]="{
                     'border-b border-b-base-200': addBorder
                     }">
  <mat-expansion-panel-header class="min-h-12 h-fit py-3">
    <mat-panel-title>
      <h4 [innerHTML]="label"></h4>
    </mat-panel-title>
  </mat-expansion-panel-header>
  @if (content[labels.indexOf(label)]) {
    <p [innerHTML]="content[labels.indexOf(label)]">
    </p>
  } @else {
    <p>
      <ng-content></ng-content>
    </p>
  }
</mat-expansion-panel>
