import { Pipe, PipeTransform } from '@angular/core';
import { Environment } from 'core/environment/environment.interface';
import { SelectItem } from 'shared/ui/basic/select/select.interface';

@Pipe({
  name: 'environmentDropdown',
  standalone: true
})
export class EnvironmentDropdownPipe implements PipeTransform {
  transform(envs: Environment[]): SelectItem[] {
    return envs.map(env => ({
      id: env.id,
      name: `${env.name}${env.customerNumbers.length ? ` (${env.customerNumbers[0]})` : ''}`,
      title: (env.customerNumbers.length ? (env.customerNumbers.join(', ')) : undefined)
    }));
  }
}
