import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import {
  ConsumptionBoardRequest,
  ConsumptionBoardResponse,
} from './portfolio-consumption/consumption.interface';
import { GroupDetail, GroupSite } from './portfolio-group/group-detail.interface';
import { GeoApiResponse } from './portfolio-masterdata/interfaces/geo-api-response.interface';
import { SiteDetails } from './portfolio-masterdata/interfaces/site-details.interface';
import { SiteDetail } from 'app/features/portfolio/portfolio-masterdata/interfaces/site-details.interface';

@Injectable({
  providedIn: 'root',
})
export class PortfolioHttpService {
  constructor(private readonly httpClient: HttpClient) {}

  getSiteConsumption(
    requestBody: ConsumptionBoardRequest,
    envId: number,
    siteId: number
  ): Observable<ConsumptionBoardResponse> {
    return this.httpClient.post<ConsumptionBoardResponse>(
      `${environment.apiUrl}/api/${envId}/portfolio/consumption/site/${siteId}`,
      requestBody
    );
  }

  getConsumption(
    requestBody: ConsumptionBoardRequest,
    envId: number
  ): Observable<ConsumptionBoardResponse> {
    return this.httpClient.post<ConsumptionBoardResponse>(
      `${environment.apiUrl}/api/${envId}/portfolio/consumption`,
      requestBody
    );
  }

  getPortfolioMasterdataSite(envId: number, id: number, year: number): Observable<SiteDetails> {
    return this.httpClient.get<SiteDetails>(
      `${environment.apiUrl}/api/${envId}/portfolio/master-data/site/${id}?year=${year}`
    );
  }

  getPortfolioMasterdataGroup(envId: number, id: number, year: number): Observable<GroupDetail> {
    return this.httpClient.get<GroupDetail>(
      `${environment.apiUrl}/api/${envId}/portfolio/master-data/group/${id}?year=${year}`
    );
  }

  getPortfolioMasterdataGroupSite(
    envId: number,
    id: number,
    year: number
  ): Observable<GroupSite[]> {
    return this.httpClient.get<GroupSite[]>(
      `${environment.apiUrl}/api/${envId}/portfolio/master-data/group/${id}/sites?year=${year}`
    );
  }

  deletePortfolioMasterdataSite(envId: number, id: number): Observable<unknown> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/api/${envId}/portfolio/site/${id}`);
  }

  getAddressData(query: string): Observable<GeoApiResponse[]> {
    const url = `https://geocode.maps.co/search?q=${encodeURI(
      query
    )}&api_key=65b1151e3564d237133098lsk41672c`;
    const options = {
      headers: {},
    };
    return this.httpClient.get<GeoApiResponse[]>(url, options);
  }

  updateSiteTags(envId: number, siteId: number, tagIds: number[]): Observable<SiteDetail> {
    return this.httpClient.put<SiteDetail>(
      `${environment.apiUrl}/api/${envId}/sites/${siteId}/tags`,
      {
        tagIds,
      }
    );
  }

  getSiteTags(envId: number, siteId: number): Observable<SiteDetail> {
    return this.httpClient.get<SiteDetail>(`${environment.apiUrl}/api/${envId}/sites/${siteId}`);
  }
}
