import { Group, SiteOfGroup } from 'core/groups/groups.interface';

export class GroupHelper {
  static getGroupHierarchy(groups: Group[], groupId: number): Group[] {
    let hierarchy: Group[] = [];
    const targetGroup = groups.find((group) => group.id === groupId);
    if (targetGroup) {
      return [targetGroup];
    } else {
      for (const element of groups) {
        const childHierarchy = GroupHelper.getGroupHierarchy(element.groups, groupId);
        if (childHierarchy.length) {
          hierarchy = [element, ...childHierarchy];
          break;
        }
      }
    }
    return hierarchy;
  }

  static getSiteHierarchy(groups: Group[], siteId: number): Group[] {
    let hierarchy: Group[] = [];
    const targetGroup = groups.find(
      (group) => group.sites?.length && group.sites.find((s) => s.id === siteId)
    );
    if (targetGroup) {
      return [targetGroup];
    } else {
      for (const element of groups) {
        const childHierarchy = GroupHelper.getSiteHierarchy(element.groups, siteId);
        if (childHierarchy.length) {
          hierarchy = [element, ...childHierarchy];
          break;
        }
      }
    }
    return hierarchy;
  }

  static deleteSiteFromChildGroup(groups: Group[], siteId: SiteOfGroup): boolean {
    // the Whole Manipulation happens By Reference -> that's why the returned Value is not critical at this Point.
    // I changed the Value to Boolean to stop Recursion at some Point.
    try {
      const parentGroupIndex = groups.findIndex(
        (group) => group.sites?.length && !!group.sites.find((s) => s.id === siteId.id)
      );
      if (parentGroupIndex > -1 && groups[parentGroupIndex].sites) {
        groups[parentGroupIndex].sites = (groups[parentGroupIndex]?.sites ?? []).filter(
          (site) => site.id !== siteId.id
        );
        return true;
      } else {
        let isFoundAndDeleted = false;
        for (const element of groups) {
          isFoundAndDeleted = GroupHelper.deleteSiteFromChildGroup(element.groups, siteId);
          if (isFoundAndDeleted) {
            break;
          }
        }
        return isFoundAndDeleted;
      }
    } catch (e) {
      console.error('deleteSiteFromChildGroup', e);
      return false;
    }
  }

  static addSiteToGroup(groups: Group[], site: SiteOfGroup, targetGroupId: number): boolean {
    // the Whole Manipulation happens By Reference -> that's why the returned Value is not critical at this Point.
    // I changed the Value to Boolean to stop Recursion at some Point.
    try {
      const targetGroupIndex = groups.findIndex((group) => group.id === targetGroupId);
      if (targetGroupIndex > -1) {
        groups[targetGroupIndex].sites = [site, ...(groups[targetGroupIndex]?.sites ?? [])];
        groups[targetGroupIndex].hasSites = true;
        return true;
      } else {
        let isFoundAndAdded = false;
        for (const element of groups) {
          isFoundAndAdded = GroupHelper.addSiteToGroup(element.groups, site, targetGroupId);
          if (isFoundAndAdded) {
            break;
          }
        }

        return isFoundAndAdded;
      }
    } catch (e) {
      console.error('addSiteToGroup', e);
      return false;
    }
  }

  static deleteGroupFromChildGroup(groups: Group[], targetGroupId: number): Group[] {
    try {
      const parentGroup = groups.find((group) => group.id === targetGroupId);
      if (parentGroup) {
        return groups.filter((g) => g.id !== targetGroupId);
      } else {
        for (const element of groups) {
          const recursiveGroups = GroupHelper.deleteGroupFromChildGroup(
            element.groups,
            targetGroupId
          );
          if (recursiveGroups.length !== element.groups.length) {
            element.groups = recursiveGroups;
            break;
          }
        }
      }
      return groups;
    } catch (e) {
      console.error('deleteGroupFromChildGroup', e);
      return groups;
    }
  }

  static deleteChildSiteFromGroupSites(sites: SiteOfGroup[], targetSiteId: number): SiteOfGroup[] {
    try {
      const parentSite = sites.find((site) => site.id === targetSiteId);
      if (parentSite) {
        return sites.filter((s) => s.id !== targetSiteId);
      } else {
        for (const element of sites) {
          const recursiveGroups = GroupHelper.deleteChildSiteFromGroupSites(
            element.sites,
            targetSiteId
          );
          if (recursiveGroups.length !== element.sites.length) {
            element.sites = recursiveGroups;
            break;
          }
        }
      }
      return sites;
    } catch (e) {
      console.error('deleteChildSiteFromGroupSites', e);
      return sites;
    }
  }

  static addGroupToGroup(groups: Group[], childGroup: Group, targetGroupId: number): Group[] {
    try {
      const targetGroupIndex = groups.findIndex((group) => group.id === targetGroupId);
      if (targetGroupIndex > -1) {
        groups[targetGroupIndex].groups = [childGroup, ...(groups[targetGroupIndex]?.groups ?? [])];
        return groups;
      } else {
        for (const element of groups) {
          const recursiveGroups = GroupHelper.addGroupToGroup(
            element.groups,
            childGroup,
            targetGroupId
          );
          if (recursiveGroups.length !== element.groups.length) {
            element.groups = recursiveGroups;
            break;
          }
        }
      }

      return groups;
    } catch (e) {
      console.error('addGroupToGroup', e);
      return groups;
    }
  }

  static renameGroup(groups: Group[], targetGroupId: number, name: string): Group[] {
    try {
      const targetGroupIndex = groups.findIndex((group) => group.id === targetGroupId);
      if (targetGroupIndex > -1) {
        groups[targetGroupIndex].name = name;
        return groups;
      } else {
        for (const element of groups) {
          const recursiveGroups = GroupHelper.renameGroup(element.groups, targetGroupId, name);
          if (recursiveGroups.length !== element.groups.length) {
            element.groups = recursiveGroups;
            break;
          }
        }
      }

      return groups;
    } catch (e) {
      console.error('renameGroup', e);
      return groups;
    }
  }

  static isAllowToMoveGroup(group: Group, targetGroup: Group): boolean {
    return (
      group.id !== targetGroup.id &&
      GroupHelper.flattenGroups(group.groups).every((g) => g.id !== targetGroup.id)
    );
  }

  static isAllowToMoveSite(site: SiteOfGroup, targetGroup: Group): boolean {
    return site.parentGroupId !== targetGroup.id;
  }

  static flattenGroups(groups: Group[]): Group[] {
    const flattened: Group[] = [];

    const flatten = (group: Group) => {
      flattened.push(group);
      if (group.groups && group.groups.length > 0) {
        group.groups.forEach((subGroup) => {
          flatten(subGroup);
        });
      }
    };

    groups.forEach((group) => {
      flatten(group);
    });

    return flattened;
  }

  // two seperated Function for sites and Groups, maybe for Future requirements, we need different sorting for sites and Groups
  static sortGroupsAlphabetically(groups: Group[]): Group[] {
    return groups.sort((a, b) => a.name.localeCompare(b.name));
  }

  static sortSitesAlphabetically(sites: SiteOfGroup[]): SiteOfGroup[] {
    return sites.sort((a, b) => a.name.localeCompare(b.name));
  }

  static sortGroupAndSitesAlphabetically(group: Group): Group {
    return {
      ...group,
      groups: GroupHelper.sortGroupsAlphabetically(group.groups),
      sites: group.sites ? GroupHelper.sortSitesAlphabetically(group.sites) : group.sites,
    };
  }

  static sortGroupsAndChildGroupsAlphabetically(groups: Group[]): Group[] {
    return GroupHelper.sortGroupsAlphabetically(groups).map((g) => {
      GroupHelper.loopThrowGroupsAndSort(g.groups);
      return GroupHelper.sortGroupAndSitesAlphabetically(g);
    });
  }

  static loopThrowGroupsAndSort(groups: Group[]): Group[] {
    if (!groups.length) {
      return [];
    }
    return groups.map((g) => {
      GroupHelper.loopThrowGroupsAndSort(g.groups);
      return GroupHelper.sortGroupAndSitesAlphabetically(g);
    });
  }
}
