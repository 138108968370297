import { createAction, props } from '@ngrx/store';
import { reloadConsumptionAfterEditingNavigationIsFinishedStateType } from 'app/features/portfolio/+state/portfolio.reducer';
import { GeoApiResponse } from 'app/features/portfolio/portfolio-masterdata/interfaces/geo-api-response.interface';
import { SiteDetails } from 'app/features/portfolio/portfolio-masterdata/interfaces/site-details.interface';
import { Tag } from 'core/tags/interface';
import { FilterDateRange, FilterTab } from 'shared/ui/filter/filter-tab.interface';

import {
  ConsumptionBoardId,
  ConsumptionInfo,
} from '../portfolio-consumption/consumption.interface';
import { GroupDetail, GroupSite } from '../portfolio-group/group-detail.interface';

export const storeConsumptionBoardRequestStatus = createAction(
  '[Portfolio Screen] Store Consumption Board Request Status',
  props<{ loading: boolean }>()
);

export const storeConsumptionBoardData = createAction(
  '[Portfolio Screen] Store Consumption Board Data',
  props<{
    consumptionBoard: ConsumptionInfo;
    consumptionBoardId: ConsumptionBoardId;
  }>()
);

export const storeSelectedHeaderTab = createAction(
  '[Portfolio Screen] Store selected Header Tab',
  props<{ selectedHeaderTab: FilterTab }>()
);

export const storeSelectedMainFilter = createAction(
  '[Portfolio Screen] Store selected Main Filter',
  props<{ selectedMainFilter: FilterTab }>()
);

export const storeSelectedChartSubFilter = createAction(
  '[Portfolio Screen] Store selected Chart Sub Filter',
  props<{ selectedChartSubFilter: string }>()
);

export const storeSelectedSiteTableYear = createAction(
  '[Portfolio Screen] Store selected Site Table Year',
  props<{ selectedSiteTableYear: FilterDateRange }>()
);

export const updateMasterdataIsLoading = createAction(
  '[Portfolio Screen] Masterdata is Loading',
  props<{ masterDataIsLoading: boolean }>()
);
export const loadSiteDetails = createAction(
  '[Portfolio] load Site Details',
  props<{
    envId: number;
    siteId: number | string;
    year: number;
  }>()
);
export const loadGroupDetails = createAction(
  '[Portfolio] load Group Details',
  props<{
    envId: number;
    groupId: number | string;
  }>()
);
export const storeSiteDetails = createAction(
  '[Portfolio] store Site Details',
  props<{
    siteDetails?: SiteDetails;
  }>()
);

export const storeGroupDetails = createAction(
  '[Portfolio] store Group Details',
  props<{
    groupDetails?: GroupDetail;
  }>()
);

export const storeGroupSites = createAction(
  '[Portfolio] store Group Sites',
  props<{
    groupSites: GroupSite[];
  }>()
);

export const resetSiteDetails = createAction('[Portfolio Screen] Reset Site Details');

export const resetGroupDetails = createAction('[Portfolio Screen] Reset Group Details');

export const updateSiteGeoLocation = createAction(
  '[Portfolio] update Site Location',
  props<{
    siteGeoLocation: GeoApiResponse[];
  }>()
);

export const updateSiteDetailsLoading = createAction(
  '[Portfolio] update Site Details Loading',
  props<{
    masterDataIsLoading: boolean;
  }>()
);

export const updatePortfolioData = createAction('[Portfolio] Update Data');

export const updatePossibleEditingPortfolioNavigationFinishedState = createAction(
  '[Portfolio] Update Possible Editing Portfolio Navigation Finished State',
  props<{
    possibleState: reloadConsumptionAfterEditingNavigationIsFinishedStateType;
  }>()
);

export const updateEditingPortfolioNavigationFinishedState = createAction(
  '[Portfolio] Update Editing Portfolio Navigation Finished',
  props<{
    reloadState: reloadConsumptionAfterEditingNavigationIsFinishedStateType;
  }>()
);
export const updateGroupDetailsLoading = createAction(
  '[Portfolio] update Group Details Loading',
  props<{
    masterDataIsLoading: boolean;
  }>()
);

export const loadSiteTags = createAction(
  '[Portfolio] load Site Tags',
  props<{
    envId: number;
    siteId: number;
  }>()
);

export const storeSiteTags = createAction(
  '[Portfolio] store Site Tags',
  props<{
    tags: Tag[];
  }>()
);

export const updateSiteTags = createAction(
  '[Portfolio] update Site Tags',
  props<{
    envId: number;
    siteId: number;
    tags: number[];
  }>()
);

export const resetPortfolioState = createAction('[Portfolio] Reset State');

export const loadFailure = createAction('[Portfolio] Load Failure', props<{ error: Error }>());

export const loadSuccess = createAction('[Portfolio] Load Success');
