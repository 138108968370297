import { InjectionToken } from '@angular/core';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';
import { coreReducer, CoreState, initialCoreState } from 'core/+state/core.reducer';

export interface RootState {
  core: CoreState;
}

export const initialRootState: RootState = {
  core: initialCoreState,
  // shared: null
};

export const rootStateReducer: ActionReducer<RootState> = combineReducers({
  core: coreReducer,
});

export const ROOT_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<RootState>>('State', {
  factory: () => ({
    core: coreReducer,
  }),
});
