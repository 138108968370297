import { createFeatureSelector, createSelector } from '@ngrx/store';
import { headerDataConfig } from 'app/features/portfolio/portfolio-consumption/consumption-table.config';
import { ConsumptionMapper } from 'app/features/portfolio/portfolio-consumption/consumption.mapper';
import { PortfolioChartMapper } from 'app/features/portfolio/utils/portfolio-chart.mapper';
import { PortfolioMasterdataMapper } from 'app/features/portfolio/utils/portfolio-masterdata.mapper';
import { PortfolioMonitoringMapper } from 'app/features/portfolio/utils/portfolio-monitoring.mapper';
import {
  ADDRESS_NOT_FOUND,
  LANDMARK_NOT_FOUND,
} from 'app/features/portfolio/utils/portfolio.constants';
import { AnalyticalCategories } from 'app/shared/interfaces/consumption-type-button.interface';
import { ChartItem } from 'app/shared/ui/chart/chart.interface';
import {
  selectAdjacentYearsDependsOnGlobalSelectedYear,
  selectGlobalSelectedYear,
  selectGlobalSelectedYearAsFilterRange,
  selectYearsWithData,
} from 'core/+state/core.selectors';
import { HelperService } from 'shared/helper/helper.service';
import { OsmHelper } from 'shared/helper/osm-helper';
import { LabelConfig } from 'shared/ui/basic/label/label.interface';
import { FilterDateRange, FilterTab } from 'shared/ui/filter/filter-tab.interface';
import {
  MonitoringCardsSkeletonType,
  MonitoringCardType,
} from 'shared/ui/monitoring-card/monitoring-card.skeleton';

import { MonitoringCardData } from 'shared/ui/monitoring-card/monitoring-card-data.interface';
import { ConsumptionInfo } from '../portfolio-consumption/consumption.interface';
import { headerMasterdataConfig } from '../portfolio-group-masterdata/group-masterdata-tabel.config';
import { LandmarkInterface } from '../portfolio-masterdata/interfaces/landmark.interface';
import { SiteDetails } from '../portfolio-masterdata/interfaces/site-details.interface';
import { portfolioFeatureKey, PortfolioState } from './portfolio.reducer';
import { AllValueBarDiagnoseCardSkeleton } from 'shared/constants/value-bar-diagnse-card-skeleton';
import { Utils } from 'shared/helper/utils';

export const selectPortfolioState = createFeatureSelector<PortfolioState>(portfolioFeatureKey);

export const selectConsumptionBoardId = createSelector(
  selectPortfolioState,
  (state) => state?.consumptionBoardId
);

export const selectIsConsumptionBoardLoading = createSelector(
  selectPortfolioState,
  (state) => state.loading
);

export const selectSelectedFilter = createSelector(
  selectPortfolioState,
  (state) => state?.selectedMainFilter
);

export const selectConsumptionInfo = createSelector(
  selectPortfolioState,
  (state) => state?.consumptionBoard
);

export const selectAlreadyLoadedPortfolioYears = createSelector(selectConsumptionInfo, (state) =>
  state ? Object.keys(state).map((element) => parseInt(element, 10)) : []
);

export const getRequiredAdditionalDataKeyDependsOnPortfolioType = (
  portfolioType: AnalyticalCategories,
  childPropKey: string
): string =>
  portfolioType === 'consumption' && childPropKey === HelperService.heatingLabel
    ? HelperService.hotWaterEnergy
    : '';

export const selectConsumptionAsBarChart = createSelector(
  selectPortfolioState,
  selectSelectedFilter,
  (state, selectedFilter) =>
    state?.consumptionBoard
      ? PortfolioChartMapper.getPortfolioConsumptionTransformedBarChart(
          state?.consumptionBoard,
          (selectedFilter?.left[0]?.value as AnalyticalCategories) || 'consumption',
          getRequiredAdditionalDataKeyDependsOnPortfolioType
        )
      : []
);

export const checkAndFilterUnknownItem = (data: ChartItem[]): ChartItem[] =>
  data.map((item) => {
    if (item.value === 'unknown') {
      item.data = item.data.filter((itemData) => (itemData.values?.value || 0) >= 1);
    }

    return item;
  });

export const selectConsumptionAsMediaDistributionChart = createSelector(
  selectPortfolioState,
  selectSelectedFilter,
  (state, selectedFilter) =>
    state?.consumptionBoard
      ? checkAndFilterUnknownItem(
          PortfolioChartMapper.getPortfolioMediaDistributionTransformedChart(
            state.consumptionBoard,
            (selectedFilter?.left[0]?.value as AnalyticalCategories) || 'consumption'
          )
        )
      : []
);

const transformToMonitoringCardArray = (data: MonitoringCardType): MonitoringCardData[] => {
  const result: MonitoringCardData[] = [];
  Object.keys(data).forEach((key) => {
    result.push(data[key as MonitoringCardsSkeletonType]);
  });
  return result;
};
export const selectConsumptionAsMonitoringCards = createSelector(selectPortfolioState, (state) =>
  state?.consumptionBoard
    ? transformToMonitoringCardArray(
        PortfolioMonitoringMapper.getTransformedConsumptionData(state.consumptionBoard)
      )
    : []
);

export const selectConsumptionDiagnoseCardsObject = createSelector(
  selectPortfolioState,
  selectGlobalSelectedYear,
  (state, globalSelectedYear) =>
    state?.consumptionBoard && globalSelectedYear
      ? PortfolioMonitoringMapper.consumptionInfoToValueDiagnoseCardValues(
          AllValueBarDiagnoseCardSkeleton,
          state.consumptionBoard,
          globalSelectedYear,
          Utils.getBarsDisplayedYearsKeys(state.consumptionBoard, globalSelectedYear)
        )
      : undefined
);

export const selectConsumptionDiagnoseCards = createSelector(
  selectConsumptionDiagnoseCardsObject,
  (data) => (data ? Object.entries(data).map(([, value]) => value) : [])
);

export const selectSelectedSiteTableYear = createSelector(
  selectPortfolioState,
  selectYearsWithData,
  (state, yearsWithData) =>
    state.selectedSiteTableYear
      ? state.selectedSiteTableYear
      : {
          from: yearsWithData.length > 0 ? `${yearsWithData[yearsWithData.length - 1]}` : '',
          until: '',
        }
);

export const selectSiteTableYearFilterTab = createSelector(
  selectGlobalSelectedYearAsFilterRange,
  (year) =>
    ({
      left: [
        {
          label: 'filter.portfolio-objects',
          icon: './assets/icons/navigation/monitoring.svg',
          active: false,
        },
      ],
      selectedYear: year,
    } as FilterTab)
);

export const selectChartYearFilterRange = createSelector(
  selectAdjacentYearsDependsOnGlobalSelectedYear,
  (years) =>
    ({
      from: `${years[0]}`,
      until: `${years[years.length - 1]}`,
    } as FilterDateRange)
);

export const selectPortfolioSelectedChartSubFilter = createSelector(
  selectPortfolioState,
  (state) => state?.selectedChartSubFilter
);

export const selectPortfolioConsumptionChartSelectedFilter = createSelector(
  selectYearsWithData,
  selectPortfolioSelectedChartSubFilter,
  (timeSeries, subFilter) => [
    {
      left: [
        {
          label: subFilter ?? HelperService.heatingLabel,
          value: subFilter ?? HelperService.heatingLabel,
        },
      ],
      selectedYear: {
        from: timeSeries[timeSeries.length - 1]?.toString() || '',
        until: '',
      },
    },
  ]
);

export const selectPortfolioConsumptionChartLabelConfig = createSelector(
  selectSelectedFilter,
  selectPortfolioConsumptionChartSelectedFilter,
  selectPortfolioSelectedChartSubFilter,
  (selectedFilter, mainFilterTab, subFilter) => {
    const consumptionTypes = subFilter
      ? subFilter
      : mainFilterTab.length
      ? mainFilterTab[0].left[0].value
      : undefined;

    return {
      prefixText: 'common.unit-prefix-text',
      analyticalCategory: selectedFilter ? selectedFilter.left[0].value : 'consumption',
      consumptionTypes,
    } as LabelConfig;
  }
);

export const selectTransformedSiteData = createSelector(
  selectConsumptionInfo,
  selectGlobalSelectedYear,
  (data, selectedYear) =>
    selectedYear && data && data[selectedYear]
      ? ConsumptionMapper.transformSiteDataToTableDataGeneric(
          data[selectedYear].sites,
          headerDataConfig
        )
      : {}
);

export const selectGroupDetails = createSelector(selectPortfolioState, (state) => state.groupData);

export const selectGroupSites = createSelector(selectPortfolioState, (state) => state.groupSites);

export const selectTransformedGroupData = createSelector(selectGroupSites, (data) =>
  data ? ConsumptionMapper.transformGroupDataToTableDataGeneric(data, headerMasterdataConfig) : {}
);

export const selectSiteDetails = createSelector(
  selectPortfolioState,
  (state) => state.masterdataSite
);

export const selectCardContent = createSelector(selectSiteDetails, (siteDetails) =>
  siteDetails
    ? PortfolioMasterdataMapper.getMasterdataCardData(
        PortfolioMasterdataMapper.mapStaticMasterdataSite(siteDetails)
      )
    : undefined
);

export const selectMeteringData = createSelector(selectSiteDetails, (siteDetails) =>
  siteDetails ? PortfolioMasterdataMapper.mapMeteringData(siteDetails) : undefined
);

export const selectMasterdataPortfolioCardsBySite = createSelector(
  selectSiteDetails,
  selectGlobalSelectedYear,
  (siteDetails, globalYear) =>
    siteDetails
      ? PortfolioMasterdataMapper.mapToPortfolioCardContent(
          PortfolioMasterdataMapper.mapSiteDetailToPortfolioCard(siteDetails, globalYear as number),
          globalYear as number
        )
      : undefined
);

export const selectMasterdataPortfolioInfoCardsBySite = createSelector(
  selectSiteDetails,
  (siteDetails) =>
    siteDetails
      ? PortfolioMasterdataMapper.mapSiteDetailToPortfolioInfoCard(siteDetails)
      : undefined
);

export const selectMasterdataPortfolioCardsByGroup = createSelector(
  selectGroupDetails,
  selectGlobalSelectedYear,
  (groupDetails, globalYear) =>
    groupDetails
      ? PortfolioMasterdataMapper.mapToPortfolioCardContent(
          PortfolioMasterdataMapper.mapGroupDetailToPortfolioCard(
            groupDetails,
            globalYear as number
          ),
          globalYear as number
        )
      : undefined
);

export const selectMasterdataPortfolioInfoCardsByGroup = createSelector(
  selectGroupDetails,
  selectGlobalSelectedYear,
  (groupDetails, globalYear) =>
    groupDetails && globalYear
      ? PortfolioMasterdataMapper.mapGroupDetailToPortfolioInfoCard(groupDetails)
      : undefined
);

export const selectSiteLandmarks = createSelector(
  selectSiteDetails,
  (siteDetails: SiteDetails | undefined) => {
    if (!siteDetails || !siteDetails?.geoLocation?.length) {
      return [LANDMARK_NOT_FOUND];
    }
    const address = OsmHelper.getAddressStringForGeoApi(
      OsmHelper.transformSiteDetailsToAddress(siteDetails)
    );

    return [
      {
        name: address ?? '',
        lat: siteDetails?.geoLocation?.length ? siteDetails?.geoLocation[0].lat : '0.0',
        lng: siteDetails?.geoLocation?.length ? siteDetails.geoLocation[0].lon : '0.0',
        label: address ?? '',
      },
    ];
  }
);

export const selectGroupLandmarks = createSelector(
  selectConsumptionInfo,
  selectGlobalSelectedYear,
  (consumptionInfo: ConsumptionInfo | undefined, year: number | undefined) => {
    if (!year) {
      return [];
    }
    if (!consumptionInfo || !consumptionInfo[year].sites.length) {
      return [LANDMARK_NOT_FOUND];
    }

    const result: LandmarkInterface[] = [];
    const addresses = consumptionInfo[year].sites.map((site) => site.addresses[0]);
    for (const address of addresses) {
      const name = OsmHelper.getAddressStringForGeoApi(address);
      result.push({
        name,
        // lat: address?.geoLocation?.length
        // 	? address?.geoLocation[0].lat
        // 	: '0.0',
        // lng: address?.geoLocation?.length
        // 	? address.geoLocation[0].lon
        // 	: '0.0',
        label: name ?? '',
        lat: '0.0',
        lng: '0.0',
      });
    }

    return result;
  }
);

export const selectShouldShowMap = createSelector(
  selectSiteLandmarks,
  (landmarks) => landmarks?.length && landmarks[0].name !== ADDRESS_NOT_FOUND
);

export const selectShouldShowLoadingSpinnerMasterdata = createSelector(
  selectPortfolioState,
  (state) => state.masterDataIsLoading
);

export const selectHeaderTab = createSelector(
  selectPortfolioState,
  (state) => state.selectedHeaderTab?.left[0].value
);

export const selectReloadSelectedGroupConsumption = createSelector(
  selectPortfolioState,
  (state) => state?.reloadConsumptionAfterEditingNavigationIsFinishedState
);

export const selectShouldReloadSelectedGroupConsumption = createSelector(
  selectPortfolioState,
  (state) => state?.reloadConsumptionAfterEditingNavigationIsFinishedState === 2
);

export const selectSiteTags = createSelector(selectPortfolioState, (state) => state.siteTags);
export const selectSiteTagsIds = createSelector(
  selectSiteTags,
  (tags) => tags?.map((tag) => tag.id) ?? []
);
