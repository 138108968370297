<div class="flex flex-col bg-white border border-slate-200 shadow-md rounded-lg min-h-90 max-h-90 tags-wrapper">
  <div class="py-1 px-4 flex items-center min-h-12">
    <mat-icon class="icon-size-6 mr-2 text-slate-400">search</mat-icon>
    <input
      (keyup)="setFilterString($event)"
      [placeholder]="'tags.search-placeholder' | translate"
      [value]="filterString$ | async"
      class="w-full text-slate-400 placeholder:text-slate-400"
      maxlength="255"
      type="text"
    />
    @if (filterString$ | async) {
      <mat-icon (click)="clearSearch()" class="cursor-pointer">close</mat-icon>
    }
  </div>
  <div class="border-t border-t-slate-200 grow px-4 overflow-y-auto overflow-x-hidden">
    <div class="flex justify-end py-1 mb-2 flex-wrap">
      @if (!enableTagActiveStateAndDisableEditMode) {
        <ista-daytona-button
          (click)="updateShowCreateInputField(true)"
          [disabled]="showCreateInputField$ | async | nullToUndefined"
          [label]="'tags.create-button-text' | translate"
          icon="add"
          type="tertiary"
        ></ista-daytona-button>
        @if (isEditingMode$ | async) {
          <ista-daytona-button
            (buttonClicked)="updateTagsAndIsEditingMode(false)"
            [label]="'tags.editing-finish-button-text' | translate"
            [color]="(selectHighlightSaveButton$ | async) ? 'accent' : 'primary'"
            class="tag-edit-button"
            icon="check"
            type="tertiary"
            [disabled]="isFormInvalid$ | async | nullToUndefined"
          ></ista-daytona-button>
        } @else {
          <ista-daytona-button
            (click)="updateIsEditingMode(true)"
            [label]="'tags.editing-button-text' | translate"
            class="tag-read-button"
            icon="edit"
            type="tertiary"
          ></ista-daytona-button>
        }
      }
    </div>
    @if (showCreateInputField$ | async) {
      <div>
        <mat-form-field class="w-full tag-create-tag-form">
          <input
            (keydown)="onCreateKeyDown($event)"
            [formControl]="createTagNameCtrl"
            [placeholder]="'tags.create-placeholder' | translate"
            autofocus
            class="text-slate-400 placeholder:text-slate-400"
            matInput
          />
          <mat-icon
            (click)="resetCtrlAndHideCreateInputField()"
            class="icon-size-6 cursor-pointer"
            color="primary"
          >
            close
          </mat-icon>
          @if (isRequiredError || isMaxLengthError) {
            <mat-error>
              {{ 'tags.create-tag-error-message' | translate }}
            </mat-error>
          }
          @if (isNameAlreadyExistError) {
            <mat-error>
              {{ 'tags.create-tag-name-exist-error-message' | translate }}
            </mat-error>
          }
        </mat-form-field>
      </div>
    }
    @for (ctrl of tagsFormArray.controls; track ctrl.value.id) {
      <div>
        @if (enableTagActiveStateAndDisableEditMode) {
          <ista-daytona-tag-active-state class="tag-state-mode-html" [formControl]="ctrl">{{ ctrl.value.name }}
          </ista-daytona-tag-active-state>
        } @else if (isEditingMode$ | async) {
          <ista-daytona-tag-edit-options
            (deleteTag)="deleteTag($event, ctrl.value)"
            [formControl]="ctrl"
            [disabled]="isDeleteInProgress$ | async | nullToUndefined"
            class="mb-2 tag-edit-mode-html"
          ></ista-daytona-tag-edit-options>
        } @else {
          <div class="px-2 py-1 text-cta text-base min-h-12 flex items-center tag-read-mode-html"
          >
            <span [title]="ctrl.value.name" class="truncate">{{ ctrl.value.name }}</span>
          </div>
        }
      </div>
    }
  </div>
</div>
