import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Environment } from './environment.interface';

@Component({
  selector: 'ista-daytona-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentComponent {
  @Input()
  envs: Environment[] | null = null;
  @Input()
  selectedEnv?: Environment | null;
  @Output()
  selectEnv: EventEmitter<Environment> = new EventEmitter<Environment>();

  public onSelectChange(env: Environment): void {
    this.selectEnv.emit(env);
  }

  public trackbyEnvId(index: number, item: Environment): number {
    return item.id;
  }
}
